import React from 'react';
import styles from './../styles.module.scss';
import { Grid } from '@mui/material';
import FooterAction from 'components/FooterAction';
import Footer from 'components/Footer';
import logo from 'assets/imgs/mintifi.png';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MetaTags from 'components/MetaTags';

const AuthGrid = ({ children }) => {
  const { authToken } = useSelector(state => state.Auth);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <MetaTags
          title="Minti"
          description="AI GENT Powered - Buy, Sell & Trade Content with Any Cryptocurrency"
        />
        <Grid
          container
          className={styles.inner}
          justifyContent="space-between"
          alignItems="center"
        >
          <Link to="/" className={styles.header}>
            <img src={logo} alt="logo" />
          </Link>
          <div className={styles.content}>{children}</div>
          <Footer />
        </Grid>
      </div>
      <FooterAction hideFooter={authToken !== null} />
    </div>
  );
};

export default AuthGrid;
