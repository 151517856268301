import React from 'react';
import { Helmet } from 'react-helmet-async';

// You can have more props. In my case, these are enough.
function MetaTags({
  title = '',
  description = '',
  image = '',
  video = '',
  name = '',
}) {
  const convertedImage = image ? URL.parse(image)?.href : '';
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <link rel="canonical" href={window.location.href} />
      <meta name="description" content={description} />
      {/* Open Graph tags (OG) */}
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* OG image tags */}

      <meta
        property="og:image"
        content={
          convertedImage
            ? convertedImage
            : 'https://zircon-cidt-assets-dev-non-encrypted.s3.us-east-1.amazonaws.com/users/logo-128x128.png'
        }
      />
      <meta
        property="og:image:secure_url"
        content={
          convertedImage
            ? convertedImage
            : 'https://zircon-cidt-assets-dev-non-encrypted.s3.us-east-1.amazonaws.com/users/logo-128x128.png'
        }
      />
      <meta property="og:image:width" content="200" />
      <meta property="og:image:alt" content={`Image of ${title} site`} />
      <meta property="og:video" content={video} />

      {/* Twitter tags */}
      <meta name="twitter:cta" content={`View on Minti`} />
      <meta name="twitter:url" content={window.location.href} />
      <meta name="twitter:domain" content="demo.minti.fun" />

      <meta name="twitter:author" content={`Owned by ${name}`} />
      <meta
        name="twitter:image"
        content={
          convertedImage
            ? convertedImage
            : 'https://zircon-cidt-assets-dev-non-encrypted.s3.us-east-1.amazonaws.com/users/logo-128x128.png'
        }
      />
      <meta name="twitter:image:alt" content={title} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:player" content={video} />
      <meta name="twitter:player:stream" content={video} />
      <meta name="twitter:player:width" content="720" />
      <meta name="twitter:player:height" content="720" />
    </Helmet>
  );
}

export default MetaTags;
