/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { getOverallGainOrLoss, isImageFile, isVideoFormat } from 'utils';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';
import CustomDataGrid from 'components/CustomDataGrid';
import Identicon from 'components/Identicon';
import { useApi } from 'api';
import { useSelector } from 'react-redux';
import { ASSETTYPES } from 'constants/types';
import { Box, Chip, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconGain from 'assets/icons/iconGain';
import IconLoss from 'assets/icons/iconLoss';

import styles from './styles.module.scss';
import { StyledButton } from 'components/StyledComponents';
import { useSalesContract } from 'contracts';
import { useAppKitAccount } from '@reown/appkit/react';
import showToast from 'utils/toast';
import { formatError } from 'utils';
import { Contracts } from 'constants/networks';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

const index = ({ setReceivedOffersCount }) => {
  const { getReceivedOffers } = useApi();
  const { address: account } = useAppKitAccount();

  const { authToken, user } = useSelector(state => state.Auth);
  const [loading, setLoading] = useState(false);
  const [sortColumns, setSortColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [totalOffers, setTotalOffers] = useState([]);
  const [lifetimeOffers, setLifetimeOffers] = useState([]);
  const [totalOffersLength, setTotalOffersLength] = useState(0);
  const [lifetimeOffersLength, setLifetimeOffersLength] = useState(0);
  const [lifetimeGrowth, setLifetimeGrowth] = useState(0);
  const [growth, setGrowth] = useState(0);
  const [period, setPeriod] = useState('month');
  const [anchorEl, setAnchorEl] = useState(null);
  const [offerAccepting, setOfferAccepting] = useState(false);
  const { acceptOffer } = useSalesContract();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = period => {
    setPeriod(period);
    handleClose();
  };

  useEffect(() => {
    if (authToken) {
      (async () => {
        try {
          setLoading(true);
          const { data: result } = await getReceivedOffers(authToken, period);
          setRows(
            result.offers.filter(offer => offer.creatorId !== user.userId)
          );
          setTotalOffers(result.totalOffers);
          setTotalOffersLength(
            result.totalOffers
              .map(a => a.count)
              .reduce((acc, value) => acc + value, 0)
          );
          setLifetimeOffers(result.lifetimeOffers);
          setLifetimeOffersLength(
            result.lifetimeOffers
              .map(a => a.count)
              .reduce((acc, value) => acc + value, 0)
          );
          setLifetimeGrowth(result.lifetimeGrowth);
          setGrowth(result.totalGrowth);
        } catch {
          //
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [authToken, period]);

  const getComparator = () => {
    return (a, b) => (a > b ? 1 : a === b ? 0 : -1);
  };

  const sortedRows = useMemo(() => {
    if (rows.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  const chart = report => {
    const data = [...report];
    if (data.length === 1) {
      // to draw a line
      data.push(data[0]);
    }
    const type = getOverallGainOrLoss(data, 'count');
    const strokeColor = type === 'gain' ? 'green' : 'red';

    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={200}
          height={60}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="gain" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="lightgreen" stopOpacity={0.8} />
              <stop offset="95%" stopColor="white" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="loss" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ff8d8d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="white" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey={'count'}
            stroke={strokeColor}
            strokeWidth={2}
            fill={`url(#${type})`}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  const handleAcceptOffer = async (nftId, contractTokenID, userAddress) => {
    if (offerAccepting) return;
    if (!account) {
      showToast('error', 'Please connect your wallet');
      return;
    }
    try {
      setOfferAccepting(true);
      const tx = await acceptOffer(
        Contracts.artion,
        contractTokenID,
        userAddress
      );
      if (tx) {
        await tx.wait();
      }
      setOfferAccepting(false);

      showToast('success', 'Offer accepted!');

      setRows(prevRows => prevRows.filter(item => item.nftId !== nftId));
      setTotalOffersLength(total => total - 1);
      setLifetimeOffersLength(total => total - 1);
      setReceivedOffersCount(total => total - 1);
    } catch (error) {
      console.log(error);
      showToast('error', formatError(error, 'Accept Offer'));
      setOfferAccepting(false);
    }
  };

  const columns = [
    {
      key: 'avatar',
      name: 'Asset',
      frozen: true,
      width: 170,
      renderCell: ({ row }) => {
        const thumbnailImage =
          row.nft.nftAssets?.find(a =>
            [ASSETTYPES.THUMBNAIL, ASSETTYPES.THUMBNAIL_VIDEO].includes(
              a.assetTypeId
            )
          )?.url ?? '';

        return (
          <Link
            to={`/explore/${row.nft.name.replaceAll(' ', '+')}`}
            className={styles.nameContainer}
          >
            <div className={styles.avatarContainer}>
              {isVideoFormat(thumbnailImage) ? (
                <video src={thumbnailImage} className={styles.avatar} />
              ) : isImageFile(thumbnailImage) ? (
                <img src={thumbnailImage} className={styles.avatar} />
              ) : (
                <Identicon
                  account={row.nft.nftId}
                  size={40}
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                />
              )}
            </div>
            <div>{row.nft.name}</div>
          </Link>
        );
      },
    },
    {
      key: 'price',
      name: 'Offer',
      minWidth: 100,
      renderCell: ({ row }) => {
        return <div>{`$${row.price} USD`}</div>;
      },
    },
    {
      key: 'creator.name',
      name: 'From',
      minWidth: 100,
      renderCell: ({ row }) => <div>{row.creator?.name}</div>,
    },
    {
      key: 'createdAt',
      name: 'Date Listed',
      minWidth: 100,
      renderCell: ({ row }) => (
        <div>{moment(row.createdAt).format('DD MMMM YYYY')}</div>
      ),
    },
    {
      key: 'expiredAt',
      name: 'Expires On',
      minWidth: 150,
      renderCell: ({ row }) => (
        <Tooltip
          placement="top"
          arrow
          title={moment(row.deadline).format('DD MMMM YYYY')}
        >
          <Chip
            avatar={
              <Box
                sx={{
                  width: '8px !important',
                  height: '8px !important',
                  borderRadius: '50%',
                  bgcolor: row.isExpired ? 'red' : 'green',
                  p: 0,
                }}
              />
            }
            variant="filled"
            sx={{
              bgcolor: row.isExpired ? '#ffeeee' : '#e5fdd4',
              color: row.isExpired ? '#bd3d33' : 'green',
              fontWeight: 500,
              fontSize: '12px',
              height: '23px',
              pl: '5px',
            }}
            label={
              row.isExpired
                ? 'Expired'
                : moment(row.deadline).format('DD MMMM YYYY')
            }
          />
        </Tooltip>
      ),
    },
    {
      key: 'accept',
      name: 'Accept Offer',
      renderCell: ({ row }) =>
        !row.isExpired && (
          <StyledButton
            disabled={offerAccepting}
            onClick={async () => {
              await handleAcceptOffer(
                row.nftId,
                row.nft.nftContractId,
                row.creator.userWallets[0].address
              );
            }}
          >
            {offerAccepting ? <ClipLoader color="#FFF" size={16} /> : 'Accept'}
          </StyledButton>
        ),
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Received Offers
        <div className={styles.subTitle}>
          Take a look at all your received offers
        </div>
      </div>

      <div className={styles.itemsContainer}>
        {/* item one */}
        {!loading ? (
          <div className={styles.items}>
            <div className={styles.header}>
              <div>Total Offers Received</div>
              <div>
                <IconButton
                  aria-label="more"
                  size="small"
                  onClick={e => setAnchorEl(e.currentTarget)}
                >
                  <MoreVertIcon />
                </IconButton>
              </div>
            </div>
            <div className={styles.details}>
              <div className={styles.info}>
                <div className={styles.siz}>
                  <div>{totalOffersLength}</div>
                </div>
                <div className={styles.secondLine}>
                  {growth >= 0 ? <IconGain /> : <IconLoss />}
                  <div className={cx(growth >= 0 ? styles.gain : styles.loss)}>
                    {growth}%
                  </div>
                  <div>vs last {period}</div>
                </div>
              </div>
              <div className={styles.chart}>{chart(totalOffers)}</div>
            </div>
          </div>
        ) : (
          <div className={styles.items}>
            <ClipLoader size={20} color="var(--primary-text-color)" />
          </div>
        )}

        {/* item two */}
        {!loading ? (
          <div className={styles.items}>
            <div className={styles.header}>
              <div>Lifetime Offers Received</div>
              {/* <div>
              <IconButton aria-label="more" size="small">
                <MoreVertIcon />
              </IconButton>
            </div> */}
            </div>
            <div className={styles.details}>
              <div className={styles.info}>
                <div className={styles.siz}>
                  <div>{lifetimeOffersLength}</div>
                </div>
                <div className={styles.secondLine}>
                  {lifetimeGrowth >= 0 ? <IconGain /> : <IconLoss />}
                  <div
                    className={cx(
                      lifetimeGrowth >= 0 ? styles.gain : styles.loss
                    )}
                  >
                    {lifetimeGrowth}%
                  </div>
                </div>
              </div>
              <div className={styles.chart}>{chart(lifetimeOffers)}</div>
            </div>
          </div>
        ) : (
          <div className={styles.items}>
            <ClipLoader size={20} color="var(--primary-text-color)" />
          </div>
        )}
      </div>

      <div className={styles.gridContainer}>
        <CustomDataGrid
          mainHeader="Received Offers"
          subHeader={rows.length === 1 ? '1 offer' : `${rows.length} offers`}
          columns={columns}
          setRows={setRows}
          setSortColumns={setSortColumns}
          sortColumns={sortColumns}
          sortedRows={sortedRows}
          rowHeight={60}
        />
      </div>

      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 200,
            maxHeight: 400,
            border: '1px solid var(--border-color)',
            filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiMenuItem-root': {
              mr: 1,
              ml: 1,
              borderRadius: 'var(--border-radius)',
              pt: 1,
              pb: 1,
              color: 'var(--primary-text-color)',
            },
            '& .selected': {
              bgcolor: 'var(--secondary-background-color)',
              '&:hover': {
                bgcolor: 'var(--third-background-color)',
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          className={period === 'day' ? 'selected' : ''}
          onClick={() => handleClick('day')}
        >
          Last Day
        </MenuItem>
        <MenuItem
          className={period === 'week' ? 'selected' : ''}
          onClick={() => handleClick('week')}
        >
          Last Week
        </MenuItem>
        <MenuItem
          className={period === 'month' ? 'selected' : ''}
          onClick={() => handleClick('month')}
        >
          Last Month
        </MenuItem>
        <MenuItem
          className={period === 'year' ? 'selected' : ''}
          onClick={() => handleClick('year')}
        >
          Last Year
        </MenuItem>
      </Menu>
    </div>
  );
};

export default index;
