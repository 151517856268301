import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { ClipLoader } from 'react-spinners';
// import Skeleton from 'react-loading-skeleton';

// import { formatNumber } from 'utils';
import PriceInput from 'components/PriceInput';

import Modal from '../Modal';
import styles from '../Modal/common.module.scss';
import InputError from '../InputError';

const OfferModal = ({
  visible,
  onClose,
  onMakeOffer,
  confirming,
  approveContract,
  contractApproving,
  contractApproved,
  token,
}) => {
  const [price, setPrice] = useState('');
  const [endTime, setEndTime] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  );
  const [selected, setSelected] = useState([]);
  const [inputError, setInputError] = useState(null);

  useEffect(() => {
    if (visible) {
      setPrice('');
      setEndTime(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
      setSelected([token]);
    }
  }, [visible]);

  const handleMakeOffer = () => {
    let quant = 1;

    onMakeOffer(selected[0], price, quant, endTime);
  };

  const validateInput = () => {
    if (price.length === 0 || parseFloat(price) == 0) return false;
    if (endTime.getTime() < new Date().getTime()) return false;
    return true;
  };

  return (
    <Modal
      visible={visible}
      title="Place your offer"
      onClose={onClose}
      submitDisabled={
        contractApproving || confirming || !validateInput() || inputError
      }
      submitLabel={
        contractApproved ? (
          confirming ? (
            <ClipLoader color="#FFF" size={16} />
          ) : (
            'Place Offer'
          )
        ) : contractApproving ? (
          'Approving Item'
        ) : (
          'Approve Item'
        )
      }
      onSubmit={() =>
        contractApproved
          ? !confirming && validateInput()
            ? handleMakeOffer()
            : null
          : approveContract(selected[0], price, onClose)
      }
      showCloseIcon={false}
    >
      <div className={styles.formGroup}>
        <div className={styles.formLabel}>Price</div>
        <div className={styles.formInputCont}>
          {/* <div className={styles.select}>
            <div className={styles.selectedToken}>
              <img src={selected[0]?.icon} className={styles.tokenIcon} />
              <div className={styles.tokenSymbol}>{selected[0]?.symbol}</div>
            </div>
          </div> */}
          <PriceInput
            className={styles.formInput}
            placeholder="0.00"
            decimals={selected[0]?.decimals || 0}
            value={'' + price}
            onChange={setPrice}
            disabled={confirming}
            onInputError={err => setInputError(err)}
          />
          {/* <div className={styles.usdPrice}>
            {!isNaN(selected[0]?.price) && selected[0]?.price !== null ? (
              `$${formatNumber(
                ((parseFloat(price) || 0) * selected[0]?.price).toFixed(2)
              )}`
            ) : (
              <Skeleton width={100} height={24} />
            )}
          </div> */}
        </div>
        <InputError text={inputError} />
      </div>
      <div className={styles.formGroup}>
        <div className={styles.formLabel}>Offer Expiration</div>
        <div className={styles.formInputCont}>
          <Datetime
            value={endTime}
            onChange={val => setEndTime(val.toDate())}
            inputProps={{
              className: styles.formInput,
              onKeyDown: e => e.preventDefault(),
              disabled: confirming,
            }}
            closeOnSelect
            isValidDate={cur =>
              cur.valueOf() > new Date().getTime() - 1000 * 60 * 60 * 24
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default OfferModal;
