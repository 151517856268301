import React from 'react';
import styles from './../styles.module.scss';
import LicenseGrid from '../ExternalDocumentsGrid';

import Header from 'components/header';
import FooterAction from '../../../components/FooterAction';

const TermsOfUse = () => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Header border />
        <LicenseGrid
          lastUpdated="September 13, 2022"
          title="Minti Terms Of Use"
          description=""
          url="/files/terms of service.pdf"
        >
          <div className={styles.authForm}>
            <h2>
              BY CLICKING TO ACCEPT AND/OR USING OUR SERVICE, YOU AGREE TO BE
              BOUND BY THESE TERMS AND ALL OF THE TERMS INCORPORATED HEREIN BY
              REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS
              OR USE THE SERVICE.
            </h2>
            <p>
              The Minti Platform is owned and operated by Daozer Ltd. (Minti,”
              “we,” “us”, or “our”). These Terms of Service (“Terms”) govern
              your access to and use of the Minti website(s), our APIs, future
              mobile app (the “App”), and any other software, tools, features,
              or functionalities provided on or in connection with our services;
              including without limitation using our services to create, view,
              explore, and create Assets and use our tools, at your own
              discretion, to connect directly with others to create, purchase,
              sell, or transfer Assets on public blockchains (collectively, the
              “Service”). “Asset” in these Terms means a non-fungible token or
              similar digital item implemented on a blockchain (such as the
              Ethereum blockchain), which uses smart contracts to link to or
              otherwise be associated with certain content or data.
            </p>
            <p>
              For purposes of these Terms, “user”, “you”, and “your” means you
              as the user of the Service. If you use the Service on behalf of a
              company or other entity then “you” includes you and that entity,
              and you represent and warrant that (a) you are an authorized
              representative of the entity with the authority to bind the entity
              to these Terms, and (b) you agree to these Terms on the entity’s
              behalf.
            </p>
            <p>
              Minti is not a wallet provider, crypto exchange, broker, financial
              institution, money services business, or creditor. Minti provides
              a peer-to-peer web3 service that helps users discover and directly
              interact with each other and Assets available on public
              blockchains. We do not have custody or control over the Assets or
              blockchains you are interacting with and we do not execute or
              effectuate purchases, transfers, or sales of Assets. To use our
              Service, you must use a third-party wallet which allows you to
              engage in transactions on blockchains.
            </p>
            <p>
              Minti is not party to any agreement between any users. You bear
              full responsibility for verifying the identity, legitimacy, and
              authenticity of Assets that you purchase from third-party sellers
              using the Service and we make no claims about the identity,
              legitimacy, functionality, or authenticity of users or Assets (and
              any content associated with such Assets) visible on the Service.
            </p>
            <p>
              Because we have a growing number of services, we sometimes need to
              provide additional terms for specific services (and such services
              are deemed part of the “Service” hereunder and shall also be
              subject to these Terms). Those additional terms and conditions,
              which are available with the relevant service, then become part of
              your agreement with us if you use those services. In the event of
              a conflict between these Terms and any additional applicable terms
              we may provide for a specific service, such additional terms shall
              control for that specific service.
            </p>
            <p>
              Minti reserves the right to change or modify these Terms at any
              time and in our sole discretion. If we make material changes to
              these Terms, we will use reasonable efforts to provide notice of
              such changes, such as by providing notice through the Service or
              updating the “Last Updated” date at the beginning of these Terms.
              By continuing to access or use the Service, you confirm your
              acceptance of the revised Terms and all of the terms incorporated
              therein by reference effective as of the date these Terms are
              updated. It is your sole responsibility to review the Terms from
              time to time to view such changes and to ensure that you
              understand the terms and conditions that apply when you access or
              use the Service.
            </p>
          </div>
        </LicenseGrid>
      </div>
      <FooterAction />
    </div>
  );
};
export default TermsOfUse;
