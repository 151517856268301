import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { AreaChart, Area, ResponsiveContainer, XAxis } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import useContract from 'hooks/useContract';
import CheckIcon from '@mui/icons-material/Check';

import { IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconCopy from '@mui/icons-material/ContentCopy';

import IconGain from 'assets/icons/iconGain';
import IconLoss from 'assets/icons/iconLoss';
import { useApi } from 'api';
import { getOverallGainOrLoss, shortenAddress } from 'utils';
import showToast from 'utils/toast';

import styles from './styles.module.scss';
import { StyledButton } from 'components/StyledComponents';
import { ethers } from 'ethers';
import { useRewardContract } from 'contracts/reward';
import AuthActions from 'actions/auth.actions';
import { ClipLoader } from 'react-spinners';
import { useAppKitAccount } from '@reown/appkit/react';
import { Contracts } from 'constants/networks';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';

const index = () => {
  const dispatch = useDispatch();

  const { user, authToken } = useSelector(state => state.Auth);
  const { address: account } = useAppKitAccount();
  const [copied, setCopied] = useState(false);

  const [period, setPeriod] = useState('month');
  const [loading, setLoading] = useState(false);
  const [redeeming, setRedeeming] = useState(false);
  const [totalRewards, setTotalRewards] = useState([]);
  const [lifetimeRewards, setLifetimeRewards] = useState([]);
  const [lifetimeGrowth, setLifetimeGrowth] = useState(0);
  const [growth, setGrowth] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [rewardSymbol, setRewardSymbol] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const { getRewardHistory, getTransferSignature } = useApi();
  const { getNonce } = useContract();
  const {
    transferFromSigned,
    getDecimals,
    getRewardBalance,
    getSymbol,
  } = useRewardContract();

  const chart = report => {
    const data = [...report];

    if (data.length === 1) {
      // to draw a line
      data.push(data[0]);
    }

    const type = getOverallGainOrLoss(data, 'count');
    const strokeColor = type === 'gain' ? 'green' : 'red';

    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={200}
          height={60}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="gain" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="lightgreen" stopOpacity={0.8} />
              <stop offset="95%" stopColor="white" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="loss" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ff8d8d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="white" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey={'count'}
            stroke={strokeColor}
            strokeWidth={2}
            fill={`url(#${type})`}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  };
  const handleCopyAddress = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const redeemToken = async () => {
    setRedeeming(true);
    try {
      if (!account) {
        showToast('error', 'Please connect your wallet');
        return;
      }

      const decimals = await getDecimals();

      const amount = ethers.utils.parseUnits(
        user.unredeemedPoint.toString(),
        decimals
      );

      const nonce = await getNonce();

      const address = process.env.REACT_APP_OWNER_ADDRESS;

      const { data } = await getTransferSignature(
        authToken,
        amount.toString(),
        nonce
      );

      const tx = await transferFromSigned(
        address,
        amount.toString(),
        nonce,
        data
      );

      await tx.wait();

      const updatedUser = { ...user, unredeemedPoint: 0 };

      dispatch(AuthActions.fetchSuccess(updatedUser));

      setCurrentBalance(
        prevState => parseFloat(user.unredeemedPoint) + prevState
      );
    } catch (error) {
      console.log(error);
    } finally {
      setRedeeming(false);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = period => {
    setPeriod(period);
    handleClose();
  };
  useEffect(() => {
    if (authToken) {
      (async () => {
        try {
          setLoading(true);
          const { data: result } = await getRewardHistory(authToken, period);
          setTotalRewards(result.totalPointEarned);
          setLifetimeRewards(result.lifetimePointEarned);
          setLifetimeGrowth(result.lifetimeGrowth);
          setGrowth(result.totalGrowth);
          const currentBalance = await getRewardBalance(user.address);
          setCurrentBalance(currentBalance);
          const symbol = await getSymbol();
          setRewardSymbol(symbol);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [authToken, period]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        My Tokens
        <div className={styles.subTitle}>
          MINT contract:
          <CopyToClipboard text={Contracts.token} onCopy={handleCopyAddress}>
            <StyledButton
              endIcon={
                copied ? (
                  <CheckIcon
                    sx={{ color: 'var(--primary-text-color)', mr: '5px' }}
                  />
                ) : (
                  <IconCopy
                    sx={{ fill: 'var(--primary-text-color)', mr: '5px' }}
                  />
                )
              }
            >
              {shortenAddress(Contracts.token)}
            </StyledButton>
          </CopyToClipboard>
        </div>
      </div>

      <div className={styles.itemsContainer}>
        {/* item one */}
        {!loading ? (
          <div className={styles.items}>
            <div className={styles.header}>
              <div>Accumulated Tokens</div>
              <div>
                <IconButton
                  aria-label="more"
                  size="small"
                  onClick={e => setAnchorEl(e.currentTarget)}
                >
                  <MoreVertIcon />
                </IconButton>
              </div>
            </div>
            <div className={styles.details}>
              <div className={styles.info}>
                <div className={styles.siz}>
                  <div>
                    {totalRewards
                      .map(a => a.count)
                      .reduce((acc, value) => acc + value, 0)
                      .toFixed(0)}
                  </div>
                  <div>{rewardSymbol}</div>
                </div>
                <div className={styles.secondLine}>
                  {growth >= 0 ? <IconGain /> : <IconLoss />}
                  <div className={cx(growth >= 0 ? styles.gain : styles.loss)}>
                    {growth}%
                  </div>
                  <div>vs last {period}</div>
                </div>
              </div>
              <div className={styles.chart}>{chart(totalRewards)}</div>
            </div>
          </div>
        ) : (
          <div className={styles.items}>
            <Skeleton height={140} />
          </div>
        )}

        {/* item two */}
        {!loading ? (
          <div className={styles.items}>
            <div className={styles.header}>
              <div>Lifetime Earnings</div>
              <IconButton aria-label="more" size="small"></IconButton>
            </div>
            <div className={styles.details}>
              <div className={styles.info}>
                <div className={styles.siz}>
                  <div>
                    {lifetimeRewards
                      .map(a => a.count)
                      .reduce((acc, value) => acc + value, 0)
                      .toFixed(0)}
                  </div>
                  <div>{rewardSymbol}</div>
                </div>
                <div className={styles.secondLine}>
                  {lifetimeGrowth >= 0 ? <IconGain /> : <IconLoss />}
                  <div
                    className={cx(
                      lifetimeGrowth >= 0 ? styles.gain : styles.loss
                    )}
                  >
                    {lifetimeGrowth}%
                  </div>
                  <div>vs last {period}</div>
                </div>
              </div>
              <div className={styles.chart}>{chart(lifetimeRewards)}</div>
            </div>
          </div>
        ) : (
          <div className={styles.items}>
            <Skeleton height={140} />
          </div>
        )}

        {/* item three */}
        {!loading ? (
          <div className={styles.items}>
            <div className={styles.header}>
              <div>Unredeemed Tokens</div>
              <div></div>
            </div>
            <div className={styles.details}>
              <div className={styles.info}>
                <div className={styles.siz}>
                  <div>{user.unredeemedPoint.toFixed(0)}</div>
                  <div>{rewardSymbol}</div>
                </div>
                <div className={styles.secondLine}>
                  {user.unredeemedPoint > 0 && (
                    <StyledButton onClick={redeemToken} disabled={redeeming}>
                      {redeeming ? (
                        <ClipLoader color="#FFF" size={20} />
                      ) : (
                        'Redeem'
                      )}
                    </StyledButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.items}>
            <Skeleton height={140} />
          </div>
        )}
        {!loading ? (
          <div className={styles.items}>
            <div className={styles.header}>
              <div>Wallet Balance</div>
              <div></div>
            </div>
            <div className={styles.details}>
              <div className={styles.info}>
                <div className={styles.siz}>
                  <div>{currentBalance.toFixed(0)}</div>
                  <div>{rewardSymbol}</div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.items}>
            <Skeleton height={140} />
          </div>
        )}
      </div>

      <div className={styles.accumulatedContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={
              lifetimeRewards
                ? lifetimeRewards.map(item => ({
                    month: item.date,
                    stack1: item.count,
                  }))
                : []
            }
            margin={{ top: 40, right: 10, left: 10, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorStack1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#cbc3e3" stopOpacity={0.8} />
                <stop offset="95%" stopColor="white" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorStack2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#9a8bbf" stopOpacity={0.8} />
                <stop offset="95%" stopColor="white" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorStack3" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#6d5f9a" stopOpacity={0.8} />
                <stop offset="95%" stopColor="white" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="month" />
            <Area
              type="monotone"
              dataKey="stack1"
              stroke="#cbc3e3"
              fill="url(#colorStack1)"
            />
            <Area
              type="monotone"
              dataKey="stack2"
              stroke="#9a8bbf"
              fill="url(#colorStack2)"
            />
            <Area
              type="monotone"
              dataKey="stack3"
              stroke="#6d5f9a"
              fill="url(#colorStack3)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 200,
            maxHeight: 400,
            border: '1px solid #f4f4f4',
            filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiMenuItem-root': {
              mr: 1,
              ml: 1,
              borderRadius: 'var(--border-radius)',
              pt: 1,
              pb: 1,
              color: 'var(--primary-text-color)',
            },
            '& .selected': {
              bgcolor: 'var(--secondary-background-color)',
              '&:hover': {
                bgcolor: 'var(--third-background-color)',
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          className={period === 'day' ? 'selected' : ''}
          onClick={() => handleClick('day')}
        >
          Last Day
        </MenuItem>
        <MenuItem
          className={period === 'week' ? 'selected' : ''}
          onClick={() => handleClick('week')}
        >
          Last Week
        </MenuItem>
        <MenuItem
          className={period === 'month' ? 'selected' : ''}
          onClick={() => handleClick('month')}
        >
          Last Month
        </MenuItem>
        <MenuItem
          className={period === 'year' ? 'selected' : ''}
          onClick={() => handleClick('year')}
        >
          Last Year
        </MenuItem>
      </Menu>
    </div>
  );
};

export default index;
