import React, { useState, useEffect } from 'react';

import { StyledAccordion, StyledButton } from 'components/StyledComponents';
import { AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import cx from 'classnames';
import styles from './styles.module.scss';
import IconShare from 'assets/icons/nft/iconShare';
import IconComment from 'assets/icons/nft/iconComment';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined';

import { totalCommentsCount, totalLikes } from './NFT/utils';
import { useSignals } from '@preact/signals-react/runtime';
import { truncateBefore } from 'utils';

export const PropertyItem = ({
  title,
  children,
  expanded: defaultExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <StyledAccordion
      border="1px var(--border-color) solid !important"
      titlesize="20px !important"
      iconcolor="var(--primary-text-color)"
      paddingtitle="10px 10px"
      expanded={expanded}
      onChange={() => setExpanded(prev => !prev)}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        className={styles.inputGroup}
        id="panel1-header"
        style={{
          boxShadow: 'none',
        }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          color: 'var(--primary-text-color)',
        }}
      >
        {children}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export const NftDetailsButtons = ({
  showComments,
  onShareClicked,
  onCommentsClicked,
  onLikesClicked,
  totalShare,
  isLiked,
}) => {
  useSignals();

  return (
    <div className={styles.buttonsContainer}>
      <StyledButton
        className={styles.textButton}
        secondary="true"
        onClick={onShareClicked}
      >
        <IconShare /> Share
        <span className={styles.comments}>({totalShare})</span>
      </StyledButton>
      <StyledButton
        className={styles.textButton}
        secondary="true"
        onClick={onLikesClicked}
      >
        {isLiked ? <FavoriteIcon /> : <FavoriteBorderOutlined />} Like
        {totalLikes.value ? (
          <span className={styles.comments}>({totalLikes.value})</span>
        ) : (
          <></>
        )}
      </StyledButton>
      <StyledButton
        className={cx(styles.textButton, showComments && styles.selected)}
        secondary="true"
        onClick={onCommentsClicked}
      >
        <IconComment /> Comments
        {totalCommentsCount.value ? (
          <span className={styles.comments}>({totalCommentsCount.value})</span>
        ) : (
          <></>
        )}
      </StyledButton>
    </div>
  );
};

export const Description = ({ description }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(true);

  useEffect(() => {
    const wordCount = description?.split(' ').length;
    setIsOverflowing(
      (wordCount > 3 && description?.length > 100) ||
        description?.includes('\n')
    );
  }, [description]);

  const toggleBio = () => {
    setShowFullDescription(!showFullDescription);
  };

  const shortDescription =
    description.split('\n')[0].length < 100
      ? `${description.split('\n')[0]}...`
      : truncateBefore(description, 100, '...');
  return description?.trim() ? (
    <div
      className={cx(styles.descriptionRoot, showFullDescription && styles.full)}
    >
      <div
        className={cx(
          styles.bioText,
          showFullDescription ? styles.full : styles.short
        )}
      >
        {showFullDescription || !isOverflowing ? description : shortDescription}
        {isOverflowing && (
          <span onClick={toggleBio} className={styles.seeMore}>
            {showFullDescription ? 'Show less' : 'Show more'}
          </span>
        )}
      </div>
      <div></div>
    </div>
  ) : (
    <></>
  );
};
