import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Fab } from '@mui/material';
import NorthIcon from '@mui/icons-material/North';
import LightMode from '@mui/icons-material/LightMode';
import DarkMode from '@mui/icons-material/DarkMode';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import HeaderOptions from 'actions/header.actions';

const FooterAction = ({ showFooter } = {}) => {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();

  const { authToken } = useSelector(state => state.Auth);
  const { mode } = useSelector(state => state.HeaderOptions);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      <div className={cx(styles.footer, !showFooter && styles.hideFooter)}>
        <Fab
          className={styles.darkMode}
          aria-label="Up"
          sx={{
            position: 'absolute',
            opacity: !authToken ? 1 : 0,
            bgcolor: 'var(--primary-color)',
            color: 'var(--primary-text-color)',
            transition: 'opacity 0.25s ease-in-out',
            '&:hover': {
              color: 'var(--primary-color)',
            },
          }}
          size="small"
          onClick={() =>
            dispatch(
              HeaderOptions.toggleMode(mode === 'light' ? 'dark' : 'light')
            )
          }
        >
          {mode === 'light' ? <DarkMode /> : <LightMode />}
        </Fab>

        <Fab
          className={styles.toTop}
          aria-label="Up"
          sx={{
            position: 'absolute',
            opacity: isVisible ? 1 : 0,
            bgcolor: 'var(--primary-color)',
            color: 'white',
            transition: 'opacity 0.25s ease-in-out',
          }}
          size="small"
          onClick={handleToTop}
        >
          <NorthIcon fontSize="small" />
        </Fab>
      </div>
    </>
  );
};

export default FooterAction;
