import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import cx from 'classnames';
import {
  Tooltip,
  Menu,
  MenuItem,
  useMediaQuery,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { useAppKitAccount, useAppKitNetwork } from '@reown/appkit/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';

import Header from 'components/header';
import Identicon from 'components/Identicon';
import FollowersModal from 'components/FollowersModal';
import { shortenAddress, formatFollowers, dataUrlToFile, uuidv4 } from 'utils';
import toast from 'utils/toast';
import { useApi } from 'api';
import usePrevious from 'hooks/usePrevious';
import HeaderActions from 'actions/header.actions';
import ModalActions from 'actions/modal.actions';
import FooterAction from 'components/FooterAction';
import { CropImageModal } from 'components/CropImageModal';
import AuthActions from 'actions/auth.actions';

import iconEdit from 'assets/svgs/edit.svg';
import IconList from '@mui/icons-material/Menu';
import IconHeart from '@mui/icons-material/FavoriteBorder';
import CheckIcon from '@mui/icons-material/Check';
import IconExclamation from 'assets/icons/iconExclamation';
import IconSecurity from '@mui/icons-material/HttpsOutlined';
import { launchWebSdk } from 'utils';

import styles from './styles.module.scss';
import FilterActions from '../../actions/filter.actions';
import BlockIcon from '@mui/icons-material/Block';
import IconShare from '@mui/icons-material/IosShare';
import IconWebsite from '@mui/icons-material/Language';
import IconVerified from 'assets/icons/iconVerified';
import IconCopy from '@mui/icons-material/ContentCopy';
import { StyledAccordion, StyledButton } from 'components/StyledComponents';
import IconFS from 'assets/icons/iconFS';
import { Box } from '@mui/material';
import IconMenu from 'assets/icons/iconMenu';
import SideBar from './components/SideBar';
import MyContent from './components/MyContent';
import ReceivedOffers from './components/ReceivedOffers';
import MyTokens from './components/MyTokens';
import SentOffers from './components/SentOffers';
// import ReceivedBids from './components/ReceivedBids';
// import PlacedBids from './components/PlacedBids';
import Notifications from './components/Notifications';
import MemeTokens from './components/MemeTokens';
import NewPassword from './components/NewPassword';
import Referrals from './components/Referrals';
// import Whitelist from './components/Whitelist';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import IconToken from 'assets/icons/profile/iconToken';
import IconReceivedOffers from 'assets/icons/profile/iconReceivedOffers';
import IconSentOffers from 'assets/icons/profile/iconSentOffers';
// import IconReceivedBids from 'assets/icons/profile/iconReceivedBids';
// import IconPlacedBid from 'assets/icons/profile/iconPlacedBid';
import FAModel from './components/FAModel';
import IconNotification from 'assets/icons/profile/iconNotification';
import NotificationSettings from './components/NotificationSettings';

import IconKyc from 'assets/icons/profile/iconKyc';
// import IconWhitelist from 'assets/icons/profile/iconWhitelist';
import IconSuccess from 'assets/icons/profile/iconSuccess';
import UserBio from './components/UserBio';
import IconBell from '@mui/icons-material/NotificationsNone';
import SingItems from './components/SingleItems';
import WishList from './components/WishList';
import Likes from './components/Likes';
import NotiActions from 'actions/noti.actions';
import IconEth from 'assets/icons/iconEth';
import UserCommunities from './components/UserCommunities';
import { useAppKit, useAppKitProvider } from '@reown/appkit/react';
import { ethers } from 'ethers';
import MetaTags from 'components/MetaTags';

const AccountDetails = () => {
  const dispatch = useDispatch();
  const { chainId } = useAppKitNetwork();

  const { open } = useAppKit();
  const { address: account } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider('eip155');

  const { ref } = useResizeDetector();
  const location = useLocation();

  const isProfilePage = location?.pathname?.includes('/profile') || false;

  const {
    getUserNetworkInfo,
    getValidationString,
    followUser: _followUser,
    getFollowers,
    getFollowings,
    getFollowing,
    getItemsLiked,
    verifyWallet,
    getSumsubToken,
    getUserByName,
    checkIfWalletIsVerifiedByUser,
    uploadUserFile,
    fetchQrcode,
    getInteractionsTotal,
    getProfileDetailsCount,
    checkUserToken,
  } = useApi();

  const history = useHistory();
  const { name, tabId: currentTab, token } = useParams();
  const [previousName, setPreviousName] = useState('');
  const [uid, setUid] = useState(undefined);
  const [isFollowing, setIsFollowing] = useState(false);
  const { user: me, authToken } = useSelector(state => state.Auth);

  const { total: totalNotifications } = useSelector(
    state => state.Notifications
  );
  const userWallet = me.userWallets?.at(0);
  const fileInput = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuEl, setMenuEl] = useState(null);
  const [followingsModalVisible, setFollowingsModalVisible] = useState(false);
  const [followersModalVisible, setFollowersModalVisible] = useState(false);

  const tokens = useRef([]);
  const likes = useRef([]);
  const [followersLoading, setFollowersLoading] = useState(false);
  const followers = useRef([]);
  const followings = useRef([]);
  const [followingInProgress, setFollowingInProgress] = useState(false);
  const [count, setCount] = useState(0);
  const [likesCount, setLikesCount] = useState(0);
  const [favCount, setFavCount] = useState(0);
  const [receivedOffersCount, setReceivedOffersCount] = useState(0);
  const [sentOffersCount, setSentOffersCount] = useState(0);
  const [referralsCount, setReferralsCount] = useState(0);
  // const [receivedBidsCount, setReceivedBidsCount] = useState(0);
  // const [placedBidsCount, setPlacedBidsCount] = useState(0);
  const [memeTokensCount, setMemeTokensCount] = useState(0);
  const [avatar, setAvatar] = useState(null);
  const [bannerName, setBannerName] = useState('');
  const [loading, setLoading] = useState(true);
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const [bannerLoading, setBannerLoading] = useState(false);
  const [user, setUser] = useState({});
  const [copied, setCopied] = useState(false);
  const [tab, setTab] = useState();
  const [likeCancelSource, setLikeCancelSource] = useState(null);
  const prevAuthToken = usePrevious(authToken);
  const [walletVerified, setWalletVerified] = useState(null);
  const [kycVerified, setKycVerified] = useState();
  const [kycProcessStarted, setKYCProcessStarted] = useState(false);
  const [isMe, setIsMe] = useState(authToken && isProfilePage);
  const [
    walletIsVerifiedByAnotherUser,
    setWalletIsVerifiedByAnotherUser,
  ] = useState(false);
  const [isKycReqSent, setIsKycReqSent] = useState(false);
  const [qrcode, setQrcode] = useState('');
  const [faModel, setFAModel] = useState(false);

  const handleSetTab = _tab => {
    if (isProfilePage) {
      if (_tab !== 'single-items') {
        history.push(`/profile/${_tab}`);
      } else {
        history.push(`/profile`);
      }
    }
    setTab(_tab);
  };

  const updateInteractions = async () => {
    const { data: interactionsTotal } = await getInteractionsTotal(authToken);
    setFavCount(interactionsTotal.favorites);
    setLikesCount(interactionsTotal.likes);
  };

  useEffect(() => {
    if (isProfilePage && authToken && me?.userId) {
      setUser(prev => ({
        ...prev,
        ...me,
        followers: prev.followers,
        following: prev.following,
      }));
    }
  }, [me?.userId, me?.name, me?.bio, authToken]);

  useEffect(() => {
    if (token && me?.email) {
      (async (token, email) => {
        const { data } = await checkUserToken(token);
        if (data) {
          if (data.toLowerCase() !== email.toLowerCase()) {
            window.scrollTo(0, 0);
            dispatch(AuthActions.signOut());
            dispatch(NotiActions.resetNotifications());
            dispatch(NotiActions.resetCheckedAsset());
            window.localStorage.removeItem('nftItem');

            history.push(`/auth/sign-in?redirect_to=${location.pathname}`, {
              email: data,
            });
          }
        }
      })(token, me?.email);
    }
  }, [token, me]);
  useEffect(() => {
    if (isMe) {
      const hasRequest = (me?.whitelistRequests.length || 0) > 0;
      if (
        !hasRequest &&
        me.userStatus?.name === 'whitelist' &&
        tab === 'whitelist'
      ) {
        handleSetTab('single-items');
      }
      setUser(prev => ({
        ...prev,
        userStatus: me.userStatus,
      }));

      setIsBlacklisted(me.userStatus?.name === 'blacklist');
    }
  }, [isMe, me?.whitelistRequests, me.userStatus?.name, tab]);

  useEffect(() => {
    if (
      tab === 'kyc' &&
      me.userWallets &&
      me.userWallets.length > 0 &&
      me.userWallets[0].externalUserId &&
      !kycProcessStarted
    ) {
      (async () => {
        const {
          data: { message, data },
        } = await getSumsubToken(authToken, '');

        if (message === 'OK' && data) {
          //setShowSumSubContainer(true);
          launchWebSdk(data, onSumSubMessage);
          setKYCProcessStarted(true);
        }
      })();
    }
  }, [me, tab]);
  const getUserDetails = async _account => {
    setLoading(true);
    if (isProfilePage && authToken && me) {
      let accountDetailsResponse = {};
      if (me.address) {
        accountDetailsResponse = await getUserNetworkInfo(me.address);
        const {
          offers,
          // bids,
          userReferrals,
          interactions,
          singleItems,
          payTokens,
        } = await getProfileDetailsCount(authToken);

        setReceivedOffersCount(offers.data.receivedOffers);
        setSentOffersCount(offers.data.sentOffers);
        // setReceivedBidsCount(bids.data.receivedBids);
        // setPlacedBidsCount(bids.data.placedBids);
        setFavCount(interactions.data.favorites);
        setLikesCount(interactions.data.likes);
        setMemeTokensCount(payTokens.data);
        setCount(singleItems.data);
        setReferralsCount(userReferrals.data);
      }

      const data = {
        address: me.address,
        name: me.name,
        bio: me.bio,
        email: me.email,
        followers: accountDetailsResponse?.data?.followers || 0,
        followings: accountDetailsResponse?.data?.followings || 0,
        avatar: me.avatar,
        banner: me.banner,
        userId: me.userId,
        website: me.website,
        instagram: me.instagram,
        twitter: me.twitter,
        // userStatus: me.userStatus, // do not init here
      };

      setUser(prev => ({ ...data, userStatus: prev.userStatus }));
    } else if (_account) {
      try {
        const { data: userDto } = await getUserByName(
          _account.replaceAll('+', ' ')
        );

        if (Object.keys(userDto).length > 0) {
          if (userDto.userStatus?.name === 'blacklist') {
            history.replace('/404');
          }
          const wallet = userDto.userWallets[0]?.address;
          let userObject = {
            address: userDto.address,
            name: userDto.name,
            bio: userDto.bio,
            email: userDto.email,

            followers: 0,
            followings: 0,
            avatar: userDto.avatar,
            banner: userDto.banner,
            userId: userDto.userId,
            userStatus: userDto.userStatus,
            website: userDto.website,
            instagram: userDto.instagram,
            twitter: userDto.twitter,
          };
          if (wallet) {
            setUid(wallet);
            const accountDetailsResponse = await getUserNetworkInfo(wallet);
            userObject.followers = accountDetailsResponse?.data?.followers;
            userObject.followings = accountDetailsResponse?.data?.followings;
          }

          setUser(userObject);
          if (authToken && me.address) {
            const { data } = await getFollowing(me.address, wallet, authToken);
            setIsFollowing(data);
          }
        } else {
          history.replace('/404');
        }
      } catch (error) {
        console.log(error);
        setUser({});
      }
    }
    setLoading(false);
  };

  const signMessage = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(
        walletProvider,
        chainId
      );
      const signer = provider.getSigner();

      const { data: validationStringResponse } = await getValidationString(
        authToken
      );

      const { validationString } = validationStringResponse;
      if (!validationString) {
        toast('error', "Couldn't get validation string");
        return;
      }

      const sig = await signer?.signMessage(validationString);

      const { data: verifyWalletResponse } = await verifyWallet(
        sig,
        account,
        authToken
      );

      if (verifyWalletResponse.result) {
        setWalletVerified(true);
        const updatedUser = {
          ...me,
          address: account,
          userWallets: [
            {
              address: account,
            },
          ],
        };
        dispatch(AuthActions.signIn(updatedUser, authToken));
      } else {
        toast('error', verifyWalletResponse.message);
      }
    } catch (error) {
      console.log(error);
      toast(
        'error',
        'You need to sign the message to be able to verify your wallet.'
      );
    }
  };

  const connectedWrongWallet = () => {
    return (
      me && account && me.address?.toLowerCase() !== account?.toLowerCase()
    );
  };

  const isMobile = useMediaQuery('(max-width:1024px)');
  const executeScroll = () =>
    ref.current.scrollIntoView({ behavior: 'smooth' });
  useEffect(() => {
    setLoading(true);
    if (isProfilePage) {
      setUid(me?.address?.toLowerCase());
    }
    setLoading(false);
  }, [account, walletVerified, isProfilePage, me?.address]);
  useEffect(() => {
    const isMe = authToken && isProfilePage;
    setIsMe(isMe);
    if (!isMe) {
      handleSetTab('single-items');
    }
  }, [authToken, isProfilePage]);

  useEffect(() => {
    if (name) {
      if (previousName !== name) {
        getUserDetails(name);
        setPreviousName(name);
      }
    } else {
      getUserDetails();
    }
  }, [name]);

  useEffect(() => {
    if (me && uid && isMe) {
      setUser(prev => ({ ...prev, is2fa: me.is2fa }));
    }

    if (Object.keys(me).length > 0 && !me.is2fa) {
      handleSetTab('2fa');
    }
  }, [me.is2fa, uid, account, isMe, walletVerified]);

  useEffect(() => {
    const downloadQrcode = async token => {
      const { data: qrcodeUrl, errors } = await fetchQrcode(token);
      if (errors) {
        toast('error', errors[0].message);
      } else {
        setQrcode(qrcodeUrl);
      }
    };

    if (authToken && !me.is2fa) {
      downloadQrcode(authToken);
    }
  }, [authToken]);

  useEffect(() => {
    if (tab) {
      const element = document.getElementById('scroll');

      if (element) {
        element.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
    }
  }, [tab]);

  useEffect(() => {
    dispatch(HeaderActions.toggleSearchbar(true));
    dispatch(FilterActions.updateCategoryFilter(null));
    dispatch(FilterActions.resetStatusFilter());
  }, []);

  const updateItems = async _tokens => {
    return new Promise((resolve, reject) => {
      if (!authToken) {
        return resolve(
          _tokens.map(tk => ({
            ...tk,
            isLiked: false,
          }))
        );
      }
      let missingTokens = _tokens.map((tk, index) =>
        tk.items
          ? {
              index,
              isLiked: tk.isLiked,
              bundleID: tk._id,
            }
          : {
              index,
              isLiked: tk.isLiked,
              contractAddress: tk.contractAddress,
              tokenID: tk.tokenID,
            }
      );
      if (prevAuthToken) {
        missingTokens = missingTokens.filter(tk => tk.isLiked === undefined);
      }

      if (missingTokens.length === 0) {
        reject();
        return;
      }

      const cancelTokenSource = axios.CancelToken.source();
      setLikeCancelSource(cancelTokenSource);
      getItemsLiked(missingTokens, authToken, cancelTokenSource.token)
        .then(({ data, status }) => {
          setLikeCancelSource(null);
          if (status === 'success') {
            const newTokens = [...tokens.current];
            missingTokens.map((tk, idx) => {
              newTokens[tk.index].isLiked = data[idx].isLiked;
            });
            resolve(newTokens);
          }
        })
        .catch(() => {
          reject();
        });
    });
  };

  useEffect(() => {
    if (likeCancelSource) {
      likeCancelSource.cancel();
    }

    if (tokens.current.length) {
      updateItems(tokens.current)
        .then(_tokens => (tokens.current = _tokens))
        .catch();
    }

    if (likes.current.length) {
      updateItems(likes.current)
        .then(_likes => (likes.current = _likes))
        .catch();
    }
    // get sumsub token

    setKycVerified(userWallet?.kycProcess === 'completed' || false);
  }, [authToken, userWallet?.kycProcess]);

  useEffect(() => {
    if (
      authToken &&
      account &&
      account.toLowerCase() === me?.address?.toLowerCase()
    ) {
      checkIfWalletIsVerifiedByUser(authToken, account).then(
        response => {
          if (response.data.isVerified) {
            setWalletVerified(response.data.isVerified);
            setWalletIsVerifiedByAnotherUser(false);
          }
        },
        () => {
          setWalletVerified(false);
          setWalletIsVerifiedByAnotherUser(false);
        }
      );
    } else {
      setWalletVerified(false);
    }
  }, [authToken, account]);

  const getLinkToAccount = () => {
    if (Object.keys(user).length > 0) {
      return `${window.location.origin}/account/${user.name.replaceAll(
        ' ',
        '+'
      )}`;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    handleClose();
    toast('success', 'Link copied to clipboard!');
  };

  const handleShareOnFacebook = () => {
    handleClose();
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${getLinkToAccount()}`,
      '_blank'
    );
  };

  const handleShareToTwitter = () => {
    handleClose();
    window.open(
      `https://twitter.com/intent/tweet?text=Check%20out%20this%20account%20on%20CNFT&url=${getLinkToAccount()}`,
      '_blank'
    );
  };

  const navigateTo = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleConnectWallet = () => {
    open();
  };

  const handleKYC = async () => {
    const {
      data: { message, data },
      errors,
    } = await getSumsubToken(authToken, '');
    if (errors) {
      toast('error', errors[0].message);
    }
    if (message === 'OK' && data) {
      //setShowSumSubContainer(true);
      launchWebSdk(data, onSumSubMessage);
      setKYCProcessStarted(true);
    } else {
      toast('error', "Couldn't get sumsub token");
    }
  };

  const onSumSubMessage = (type, payload) => {
    if (payload?.reviewStatus === 'completed') {
      setIsKycReqSent(true);
      toast('success', 'The KYC authentication request was sent');
    }
  };
  const goToTab = (_tab, closeMenu) => {
    if (closeMenu) {
      setMenuEl(null);
    }
    if (_tab !== tab) {
      if (!me.is2fa) {
        _tab = '2fa';
        toast('warning', 'Please, complete the 2FA');
        handleSetTab(_tab);
        return;
      }

      if (isMobile) {
        executeScroll();
      }

      tokens.current = [];
      likes.current = [];
      handleSetTab(_tab);
    }
  };

  const handleCopyAddress = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const selectBanner = () => {
    fileInput.current?.click();
  };

  const handleSelectFile = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onload = function(e) {
        setAvatar(e.target.result);
      };
      setBannerName(file.name);
      reader.readAsDataURL(file);
    }
  };

  const onSaveBanner = async banner => {
    setBannerLoading(true);
    if (banner) {
      const file = dataUrlToFile(banner, bannerName);
      file.path = bannerName;
      const url = await uploadUserFile(authToken, 'banner', file);

      if (url) {
        dispatch(AuthActions.updateBanner(url));
        setUser(prev => ({
          ...prev,
          banner: url,
        }));
      } else {
        toast(
          'error',
          'There was a problem with the banner. Please try again later.'
        );
      }
      setBannerLoading(false);
    }
  };
  const openAccountSettings = () => {
    if (isMe && !isBlacklisted) {
      dispatch(ModalActions.showAccountModal());
    }
  };

  const fetchFollowers = async () => {
    setFollowersLoading(true);
    try {
      const { data } = await getFollowers(authToken, user.userId);
      followers.current = data;
    } catch {
      followers.current = [];
    }
    setFollowersLoading(false);
  };

  const fetchFollowings = async () => {
    setFollowersLoading(true);
    try {
      const { data } = await getFollowings(authToken, user.userId);
      followings.current = data;
    } catch {
      followings.current = [];
    }
    setFollowersLoading(false);
  };

  const showFollowers = () => {
    if (loading || !user.followers || user.followers === 0 || isBlacklisted) {
      return;
    }

    setFollowersModalVisible(true);
    fetchFollowers();
  };

  const showFollowings = () => {
    if (loading || !user.followings || user.followings === 0 || isBlacklisted) {
      return;
    }

    setFollowingsModalVisible(true);
    fetchFollowings();
  };

  const followUser = async (
    userId,
    username = '',
    followCurrentUser = false
  ) => {
    if (followingInProgress) return;
    if (!authToken) {
      toast('error', `Please, log in to follow ${username}.`);
      return;
    }
    setFollowingInProgress(true);
    try {
      const { errors } = await _followUser(userId, authToken);

      if (!errors) {
        if (followCurrentUser) {
          setIsFollowing(prev => !prev);
          return;
        }

        if (isMe) {
          const accountDetailsResponse = await getUserNetworkInfo(me.address);
          setUser(prevState => ({
            ...prevState,
            followers: accountDetailsResponse?.data?.followers,
            followings: accountDetailsResponse?.data?.followings,
          }));
        } else if (user?.address) {
          const accountDetailsResponse = await getUserNetworkInfo(user.address);
          setUser(prevState => ({
            ...prevState,
            followers: accountDetailsResponse?.data?.followers,
            followings: accountDetailsResponse?.data?.followings,
          }));
        }

        followings.current = followings.current.map(a => {
          if (a.userId === userId) {
            a.isFollowing = !a.isFollowing;
          }
          return a;
        });

        followers.current = followers.current.map(a => {
          if (a.userId === userId) {
            a.isFollowing = !a.isFollowing;
          }
          return a;
        });
      } else {
        toast('error', errors[0].message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setFollowingInProgress(false);
    }
  };

  const tabs =
    !isBlacklisted && isMe
      ? [
          {
            label: 'Single Items',
            icon: IconList,
            materialIcon: true,
            idx: 'single-items',
            count: count,
            countLoading: loading,
            group: 1,
          },
          {
            label: 'Wish List',
            icon: StarBorderIcon,
            materialIcon: true,
            idx: 'favorites',
            count: favCount,
            countLoading: loading,
            group: 1,
          },
          {
            label: 'Likes',
            icon: IconHeart,
            materialIcon: true,
            idx: 'likes',
            count: likesCount,
            countLoading: loading,
            group: 1,
          },
          {
            label: 'Tokens',
            icon: IconToken,
            idx: 'tokens',
            count: null,
            countLoading: loading,
            group: 1,
          },
          {
            label: 'Received Offers',
            icon: IconReceivedOffers,
            idx: 'received-offers',
            count: receivedOffersCount,
            countLoading: loading,
            group: 1,
          },
          {
            label: 'Sent Offers',
            icon: IconSentOffers,
            idx: 'sent-offers',
            count: sentOffersCount,
            countLoading: loading,
            group: 1,
          },
          // {
          //   label: 'Received Bids',
          //   icon: IconReceivedBids,
          //   idx: 'received-bids',
          //   count: receivedBidsCount,
          //   countLoading: loading,
          //   group: 1,
          // },
          // {
          //   label: 'Placed Bids',
          //   icon: IconPlacedBid,
          //   idx: 'placed-bids',
          //   count: placedBidsCount,
          //   countLoading: loading,
          //   group: 1,
          // },
          {
            label: 'Notifications',
            icon: IconBell,
            idx: 'notifications',
            materialIcon: true,
            count: totalNotifications,
            countLoading: loading,
            group: 1,
          },
          {
            label: 'Meme Tokens',
            icon: IconEth,
            idx: 'meme-tokens',
            count: memeTokensCount,
            countLoading: loading,
            group: 1,
          },
          {
            label: '2FA Security',
            icon: isMe && me.is2fa ? IconSuccess : IconSecurity,
            materialIcon: true,
            idx: '2fa',
            group: 2,
          },
          {
            label: me.address
              ? walletVerified && !connectedWrongWallet()
                ? 'Wallet Connected'
                : 'Connect Wallet'
              : 'Verify Wallet',
            icon:
              walletVerified && !connectedWrongWallet()
                ? IconSuccess
                : IconExclamation,
            idx: 'connect-wallet',
            group: 2,
          },
          ...(me.kycRequired || me.userWallets[0]?.kycProcess
            ? [
                {
                  label: 'KYC Verification',
                  icon: kycVerified ? IconKyc : IconExclamation,
                  idx: 'kyc',
                  group: 2,
                },
              ]
            : []),
          {
            label: 'Security',
            icon: IconSecurity,
            materialIcon: true,
            idx: 'security',
            group: 2,
          },
          // ...(me?.userStatus?.name !== 'whitelist'
          //   ? [
          //       {
          //         label: 'Whitelist',
          //         icon: IconWhitelist,
          //         idx: 'whitelist',
          //         group: 2,
          //       },
          //     ]
          //   : []),
          {
            label: 'Notification Settings',
            icon: IconNotification,
            idx: 'notification-settings',
            count: favCount,
            countLoading: loading,
            group: 2,
          },
        ]
      : [];

  useEffect(() => {
    const tabIdx = ['referrals', ...tabs.map(a => a.idx)];
    if (tabIdx.includes(currentTab)) {
      handleSetTab(currentTab);
    } else {
      handleSetTab('single-items');
    }
  }, [currentTab]);

  const renderBanner = useMemo(
    () =>
      loading || bannerLoading || !user?.userId ? (
        <Skeleton width="100%" height="100%" />
      ) : user.banner ? (
        <img src={user.banner} className={styles.bannerImg} />
      ) : (
        <div className={styles.bannerPlaceholder}></div>
      ),
    [loading, bannerLoading, user?.userId, user?.banner]
  );

  const renderAvatar = () => {
    const avatar = isMe ? me.avatar : user.avatar;

    return (
      <div className={styles.avatarWrapper} onClick={openAccountSettings}>
        {loading ? (
          <Skeleton
            width={isMobile ? 120 : 160}
            height={isMobile ? 120 : 160}
            className={styles.avatar}
          />
        ) : avatar ? (
          <img
            src={`${
              avatar.includes('mypinata')
                ? `${avatar}/?pinataGatewayToken=${process.env.REACT_APP_IPFS_ACCESS_KEY}`
                : avatar
            }`}
            className={styles.avatar}
          />
        ) : (
          <Identicon
            account={user?.userId || uuidv4()}
            size={isMobile ? 120 : 160}
            style={{
              width: isMobile ? '120px' : '160px',
              height: isMobile ? '120px' : '160px',
            }}
            className={styles.avatar}
          />
        )}
        {isMe && !isBlacklisted && (
          <div className={styles.avatarChange}>Edit Profile</div>
        )}
      </div>
    );
  };
  const renderUser = useMemo(
    () => (
      <div className={styles.usernameWrapper}>
        {loading ? (
          <Skeleton width={120} height={24} />
        ) : (
          <>
            <div className={styles.nameUser}>
              <div className={styles.nameSection}>{user?.name}</div>

              {kycVerified ? (
                <Tooltip
                  title="Verified"
                  arrow
                  className={cx({ tooltip: styles.tooltip })}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconVerified className={styles.icon} />
                  </Box>
                </Tooltip>
              ) : (
                isBlacklisted && (
                  <Tooltip
                    title="Blacklisted"
                    arrow
                    className={cx({ tooltip: styles.tooltip })}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <BlockIcon className={styles.icon} />
                    </Box>
                  </Tooltip>
                )
              )}
            </div>
            <UserCommunities userName={name || user?.name} />
            <div className={styles.followersSection}>
              <div className={styles.followers} onClick={showFollowers}>
                <span className={styles.followersNumber}>
                  {formatFollowers(user.followers || 0)}
                </span>
                followers
              </div>
              <div className={styles.followers} onClick={showFollowings}>
                <span className={styles.followersNumber}>
                  {formatFollowers(user.followings || 0)}
                </span>
                following
              </div>
            </div>
          </>
        )}
      </div>
    ),
    [
      user?.name,
      user?.followers,
      user?.followings,
      user?.userStatus?.name,
      isBlacklisted,
      loading,
      kycVerified,
    ]
  );
  const renderButtons = ({ mobilePreview } = {}) =>
    uid ? (
      <div className={cx(styles.addressWrapper, 'addressWrapper')}>
        {loading ? (
          <Skeleton width={120} height={20} />
        ) : uid && isMe ? (
          <>
            {!isMobile && (
              <StyledButton
                className={styles.referrals}
                onClick={() => setTab('referrals')}
              >
                {referralsCount} Referral{referralsCount > 1 ? 's' : ''}
              </StyledButton>
            )}
            <StyledButton
              startIcon={<IconFS />}
              className={styles.tokens}
              onClick={() => setTab('tokens')}
            >
              {me.unredeemedPoint.toFixed(0)} MINT
            </StyledButton>
            <Box
              sx={{
                display: 'flex',
                gap: '5px',
                ...(mobilePreview && {
                  justifyContent: 'space-between',
                  '& button': { flex: 1 },
                }),
              }}
            >
              <CopyToClipboard text={uid} onCopy={handleCopyAddress}>
                <StyledButton
                  endIcon={
                    copied ? (
                      <CheckIcon
                        sx={{ color: 'var(--primary-text-color)', mr: '5px' }}
                      />
                    ) : (
                      <IconCopy
                        sx={{ fill: 'var(--primary-text-color)', mr: '5px' }}
                      />
                    )
                  }
                >
                  {shortenAddress(uid)}
                </StyledButton>
              </CopyToClipboard>
            </Box>
          </>
        ) : (
          <>
            <div className={styles.buttonsSection}>
              {user.website && (
                <div
                  className={styles.btn}
                  onClick={() => navigateTo(user.website)}
                >
                  <IconWebsite />
                </div>
              )}
              {user.twitter && (
                <div
                  className={styles.btn}
                  onClick={() => navigateTo(user.twitter)}
                >
                  <XIcon />
                </div>
              )}
              {user.instagram && (
                <div
                  className={styles.btn}
                  onClick={() => navigateTo(user.instagram)}
                >
                  <InstagramIcon />
                </div>
              )}
              {user.website || user.twitter || user.instagram ? (
                <div className={cx(styles.btn, styles.vertical)}></div>
              ) : (
                <></>
              )}
              {uid ? (
                <div
                  className={styles.btn}
                  onClick={e => setAnchorEl(e.currentTarget)}
                  style={{ marginTop: '-4px' }}
                >
                  <IconShare />
                </div>
              ) : null}
            </div>
            <StyledButton
              className={isFollowing ? styles.unFollow : styles.follow}
              onClick={() => followUser(user.userId, user.name, true)}
            >
              {isFollowing ? 'Unfollow' : 'Follow'}
            </StyledButton>
          </>
        )}
      </div>
    ) : null;

  const [userMessage, setUserMessage] = useState('');
  useEffect(() => {
    if (!me.address) {
      setUserMessage('Please sign to verify your wallet');
    } else if (!account) {
      setUserMessage('Please connect your wallet');
    } else if (walletVerified) {
      setUserMessage('Your Wallet has been verified');
    } else if (connectedWrongWallet()) {
      setUserMessage('Your account is connected to a wrong wallet');
    } else if (walletIsVerifiedByAnotherUser) {
      setUserMessage('Connected wallet is already verified by another user');
    }
  }, [me, walletVerified, account, walletIsVerifiedByAnotherUser]);

  return (
    <div className={styles.container}>
      <Header isSticky="sticky" />
      {Object.keys(user).length > 0 && (
        <MetaTags title={`Minti - ${user.name}`} description={user.bio} />
      )}
      <div className={styles.profile}>
        <div className={styles.wrapper}>
          <div className={styles.banner}>
            {renderBanner}
            {!isBlacklisted && (
              <div className={styles.bannerButtons}>
                {isMe ? (
                  <div className={styles.btn} onClick={selectBanner}>
                    <input
                      ref={fileInput}
                      hidden
                      type="file"
                      onChange={handleSelectFile}
                      accept="image/*"
                    />
                    <img src={iconEdit} alt="Edit" />
                  </div>
                ) : (
                  <></>
                )}

                <div
                  className={cx(styles.btn, styles.iconMenu)}
                  onClick={e => setMenuEl(e.currentTarget)}
                >
                  <IconMenu />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.wrapperRow}>
        {renderAvatar()}
        <div>
          <div className={styles.bottomWrapperInner}>{renderUser}</div>
          <div className={styles.buttonsWrapper}>{renderButtons()}</div>
        </div>
      </div>
      <div className={styles.bio}>
        <UserBio bio={user.bio} />
      </div>
      <div className={styles.content}>
        {!isBlacklisted && isMe && (
          <div className={styles.contentSidebar}>
            <SideBar
              me={me}
              isMe={isMe}
              tabs={tabs}
              goToTab={goToTab}
              selectedTab={tab}
              authToken={authToken}
            />
          </div>
        )}
        <div
          ref={ref}
          className={cx(styles.contentBody, !isMe && styles.noPadding)}
        >
          {isMe && me?.userStatus?.name === 'blacklist' && (
            <div className={styles.blackListed}>
              This account has been blacklisted or suspended. Contact support at
              <span className={styles.blackListedContact}>info@minti.me</span>
              to resolve this issue.
            </div>
          )}
          {tab === 'my-content' ? (
            <MyContent />
          ) : tab === 'single-items' && !isBlacklisted ? (
            <div className={styles.singleItems}>
              <div className={styles.exploreAll}>
                {/* Main User's Nfts */}
                <SingItems
                  updateInteractions={updateInteractions}
                  isMe={isMe}
                  userId={user.userId}
                  setCount={setCount}
                  followUser={followUser}
                />
              </div>
            </div>
          ) : tab === 'favorites' ? (
            <div className={styles.singleItems}>
              <div className={styles.exploreAll}>
                <WishList
                  updateInteractions={updateInteractions}
                  userId={me.userId}
                />
              </div>
            </div>
          ) : tab === 'likes' ? (
            <div className={styles.singleItems}>
              <div className={styles.exploreAll}>
                <Likes
                  updateInteractions={updateInteractions}
                  userId={me.userId}
                />
              </div>
            </div>
          ) : tab === 'tokens' ? (
            <MyTokens />
          ) : tab === 'received-offers' ? (
            <ReceivedOffers setReceivedOffersCount={setReceivedOffersCount} />
          ) : tab === 'sent-offers' ? (
            <SentOffers setSentOffersCount={setSentOffersCount} />
          ) : tab === 'received-bids' ? (
            // <ReceivedBids />
            <></>
          ) : tab === 'placed-bids' ? (
            <></>
          ) : // <PlacedBids setPlacedBidsCount={setPlacedBidsCount} />
          tab === 'notifications' ? (
            <Notifications />
          ) : tab === 'meme-tokens' ? (
            <MemeTokens />
          ) : tab === 'notification-settings' ? (
            <NotificationSettings />
          ) : tab === 'connect-wallet' ? (
            <div className={styles.walletVerificationContainer}>
              <div
                className={cx(styles.walletVerificationHeader, styles.title)}
              >
                {userMessage}
              </div>
              <div className={styles.connectWallet}>
                {me && me?.address ? (
                  <>
                    <div className={styles.left}>
                      <div className={styles.walletVerificationLabel}>
                        Wallet address
                      </div>
                      <div className={styles.walletAddress}>
                        {isMobile ? shortenAddress(me?.address) : me.address}
                      </div>
                    </div>
                    <div className={styles.right}>
                      <div className={styles.addressWrapper}>
                        {walletVerified ? (
                          <StyledButton
                            startIcon={<IconSuccess />}
                            onClick={() => handleConnectWallet()}
                          >
                            Connected
                          </StyledButton>
                        ) : (
                          <StyledButton onClick={() => handleConnectWallet()}>
                            Connect Wallet
                          </StyledButton>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <StyledButton
                    onClick={() => (!account ? open() : signMessage())}
                  >
                    {`${!account ? 'Connect' : 'Verify'} Wallet`}
                  </StyledButton>
                )}
              </div>
            </div>
          ) : tab === '2fa' ? (
            <div className={styles.twoFAContainer}>
              <div className={cx(styles.twoFAHeader, styles.title)}>
                Two-factor Authentication
              </div>
              <div
                className={cx(
                  styles.twoFASubtitle,
                  styles.label2FAContainer,
                  styles.description
                )}
              >
                <p className={cx(styles.label2FA)}>
                  2FA is an extra layer of security used to make sure that
                  people trying to gain access to an online account are who they
                  say they are
                </p>
              </div>
              {isMe && !me.is2fa ? (
                <StyledButton onClick={() => setFAModel(true)}>
                  Authenticate
                </StyledButton>
              ) : (
                <StyledButton startIcon={<IconSuccess />} cancel="true">
                  Verified
                </StyledButton>
              )}
            </div>
          ) : tab === 'kyc' ? (
            <div className={styles.kycAuthentificationContainer}>
              {me && (!me?.address || !me.userWallets) ? (
                <>
                  <div
                    className={cx(
                      styles.kycAuthentificationSubtitle,
                      styles.title
                    )}
                  >
                    Please verify your Wallet before KYC
                  </div>
                  <StyledButton
                    onClick={() => history.push('/profile/connect-wallet')}
                  >
                    Verify Wallet
                  </StyledButton>
                </>
              ) : isKycReqSent ? (
                <div
                  className={cx(
                    styles.kycAuthentificationSubtitle,
                    styles.description
                  )}
                >
                  The authentication request has been sent. This may take a few
                  minutes.
                </div>
              ) : kycVerified ? (
                <>
                  <div
                    className={cx(
                      styles.kycAuthentificationSubtitle,
                      styles.description
                    )}
                  >
                    <IconSuccess />
                    KYC Authentication completed successfully
                  </div>
                </>
              ) : (
                <>
                  {!kycProcessStarted && !me.userWallets[0].externalUserId && (
                    <>
                      <div
                        className={cx(
                          styles.kycAuthentificationTitle,
                          styles.title
                        )}
                      >
                        Become a Creator
                      </div>
                      <div
                        className={cx(
                          styles.kycAuthentificationSubtitle,
                          styles.description
                        )}
                      >
                        Please, complete KYC verification to become a Minti
                        Creator
                      </div>
                      <div className={styles.tabButtonsWrapper}>
                        <StyledButton onClick={handleKYC}>
                          Click to fill out KYC form
                        </StyledButton>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          ) : // ) : tab === 'whitelist' ? (
          //   me && !me.address ? (
          //     <>
          //       <div className={styles.kycAuthentificationContainer}>
          //         <div
          //           className={cx(
          //             styles.kycAuthentificationSubtitle,
          //             styles.description
          //           )}
          //         >
          //           Please verify your Wallet before requesting whitelisted
          //         </div>
          //         <StyledButton
          //           onClick={() => history.push('/profile/connect-wallet')}
          //         >
          //           Verify Wallet
          //         </StyledButton>
          //       </div>
          //     </>
          //   ) : !kycVerified ? (
          //     <>
          //       <div className={styles.kycAuthentificationContainer}>
          //         <div
          //           className={cx(
          //             styles.kycAuthentificationSubtitle,
          //             styles.description
          //           )}
          //         >
          //           Please verify KYC before requesting whitelisted
          //         </div>
          //         <StyledButton onClick={() => history.push('/profile/kyc')}>
          //           Verify KYC
          //         </StyledButton>
          //       </div>
          //     </>
          //   ) : (
          //     <Whitelist
          //       authToken={authToken}
          //       styles={styles}
          //       hasRequest={(me?.whitelistRequests.length || 0) > 0}
          //     />
          //   )
          tab === 'security' ? (
            <div className={styles.securityContainer}>
              <div className={styles.title}>Security</div>
              <div className={styles.description}>
                Update your security settings
              </div>

              <StyledAccordion
                titlesize="16px"
                border="1px var(--border-color) solid"
                expanded
              >
                <AccordionSummary className={styles.accordionContainer}>
                  Password
                </AccordionSummary>
                <AccordionDetails>
                  <NewPassword authToken={authToken} />
                </AccordionDetails>
              </StyledAccordion>
            </div>
          ) : tab === 'referrals' ? (
            <Referrals />
          ) : (
            <></>
          )}

          {!kycVerified && (
            <div
              id="sumsub-websdk-container"
              className={cx(
                styles.sumsubContainer,
                tab !== 'kyc' && styles.hidden
              )}
            />
          )}

          {uid && !isBlacklisted ? (
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              sx={{
                '& .MuiMenuItem-root': {
                  display: 'flex',
                  justifyContent: 'flex-start',
                },
              }}
            >
              <CopyToClipboard
                text={getLinkToAccount()}
                onCopy={handleCopyLink}
              >
                <MenuItem className={styles.menuItem}>
                  <ContentCopyIcon />
                  Copy Link
                </MenuItem>
              </CopyToClipboard>
              <MenuItem
                className={styles.menuItem}
                onClick={handleShareOnFacebook}
              >
                <FacebookIcon />
                Share on Facebook
              </MenuItem>
              <MenuItem
                className={styles.menuItem}
                onClick={handleShareToTwitter}
              >
                <XIcon />
                Share to X
              </MenuItem>
            </Menu>
          ) : null}
          <Menu
            id="user-menu"
            anchorEl={menuEl}
            keepMounted
            open={Boolean(menuEl)}
            onClose={() => setMenuEl(null)}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            sx={{
              '& .MuiMenuItem-root': {
                display: 'flex',
                justifyContent: 'flex-start',
              },
            }}
          >
            <Box
              width={350}
              sx={{
                padding: '5px 15px',
                '& .addressWrapper': {
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  width: '100%',

                  button: {
                    pt: '5px !important',
                    pb: '5px !important',
                  },
                },
              }}
            >
              {isMobile && !isBlacklisted ? (
                <>
                  {renderButtons({ mobilePreview: true })}

                  {isMe && (
                    <SideBar
                      isMobile
                      me={me}
                      isMe={isMe}
                      tabs={tabs}
                      goToTab={tab => goToTab(tab, true)}
                      selectedTab={tab}
                      authToken={authToken}
                    />
                  )}
                </>
              ) : null}
            </Box>
          </Menu>

          <FollowersModal
            visible={followersModalVisible || followingsModalVisible}
            onClose={() => {
              setFollowersModalVisible(false);
              setFollowingsModalVisible(false);
            }}
            title={followersModalVisible ? 'Followers' : 'Followings'}
            users={
              followersLoading
                ? new Array(5).fill(null)
                : followersModalVisible
                ? followers.current
                : followings.current
            }
            className={styles.followersModal}
            toggleFollowUser={followUser}
            userId={me?.userId}
            history={history}
          />
        </div>
      </div>
      <FAModel
        visible={faModel}
        onClose={() => setFAModel(false)}
        qrcode={qrcode}
        authToken={authToken}
        action={data => dispatch(AuthActions.set2FA(data))}
      />
      <CropImageModal
        originAvatar={avatar}
        saveBanner={onSaveBanner}
        setOriginAvatar={setAvatar}
        isBanner={true}
      />
      <FooterAction displayContent />
      <div id="scroll" className={styles.scroll}></div>
    </div>
  );
};

export default AccountDetails;
