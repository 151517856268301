import React from 'react';
import styles from './../styles.module.scss';
import LicenseGrid from '../ExternalDocumentsGrid';

import Header from 'components/header';
import FooterAction from '../../../components/FooterAction';

const PrivacyPolicy = () => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Header border />
        <LicenseGrid
          lastUpdated="1 Jan 2024"
          title="Privacy Policy"
          description=""
          url="/files/privacy policy.pdf"
        >
          <div className={styles.authForm}>
            <p>
              This website (www.mintifi.me), the Minti Asset Platform and the
              products, services and features we make available to you as part
              of our website and the Minti Asset Platform from time to time
              (collectively, the “Service”) are provided by Daozer LTD, located
              at Trinity Chambers 4301 Road Town, Tortola BVI (“we”, “our” or
              “us”). We are the controller of personal data obtained via our
              Service, meaning we are the organisation that is legally
              responsible for deciding how and for what purposes it is used.
            </p>
            <p>
              We take your privacy very seriously. Please read this privacy
              notice carefully as it contains important information on who we
              are and how and why we collect, store, use and share any
              information relating to you (your “personal data”) in connection
              with your use of our Service. It also explains your rights in
              relation to your personal data and how to contact us or a relevant
              regulator in the event you have a complaint.
            </p>
            <p>
              Given the nature of our Service, we do not expect to collect the
              personal data of anyone under 13 years of age. If you are aware
              that any personal data of anyone under 13 years of age has been
              shared with or through our Service please let us know so that we
              can attempt to delete the information as soon as possible. This
              version of our privacy notice is primarily written for adults,
              including parents and guardians of child users. Our Terms of
              Service require all users to be at least 18 years old. Minors who
              are at least 13 years old but are under 18 years old may use a
              parent or guardian’s Minti Asset account, but only with the
              involvement of the account holder. If you are a child (under 18
              years old), and you are using your parent’s account, you are
              welcome to read this Privacy Notice if you find it useful, but we
              recommend you discuss the content of this Notice with your parent
              or legal guardian
            </p>
            <p>
              Our Service may link to other websites and services owned and
              operated by certain third parties to make additional products and
              services available to you. Those other third party websites and
              services may also gather information about you in accordance with
              their own separate privacy policies. For privacy information
              relating to these other third party websites, please consult their
              privacy policies as appropriate.
            </p>
            <p>This Privacy Notice is divided into the following sections:</p>
            <ul>
              <li>Personal data we collect about you</li>
              <li>How your personal data is collected</li>
              <li>How and why we use your personal data</li>
              <li>Marketing</li>
              <li>Who we share your personal data with</li>
              <li>How long your personal data will be kept</li>

              <li>Transferring your personal data</li>

              <li>Cookies</li>

              <li>Your rights</li>

              <li>Keeping your personal data secure</li>
              <li>How to complain</li>
              <li>Changes to this Privacy Notice</li>
              <li>How to contact us</li>
            </ul>
            <h2>PERSONAL DATA WE COLLECT ABOUT YOU</h2>
            <p>
              The personal data we collect about you depends on the particular
              activities carried out through our Service. We will collect and
              use the following personal data about you:
            </p>
            <ul>
              <li>
                your name, country of residence and email address if needed for
                KYC checks by a third party provider
              </li>
              <li>location data, if you choose to give this to us</li>
              <li>
                details of any information, feedback or other matters you give
                us by phone, email, post or via social media
              </li>
              <li>your account details, such as username and login details</li>
              <li>your activities on, and use of, our website and Service</li>
              <li>information about the Service we provide to you</li>
              <li>
                information about how you use our website, Service and
                technology systems
              </li>
              <li>
                information to check and verify your identity, eg date of birth
              </li>
              <li>your responses to surveys, competitions and promotions</li>
            </ul>
            <p>
              If you do not provide personal data we ask for where it is
              indicated to be ‘required’ at the point of collection, it may
              delay or prevent us from providing the Service to you.
            </p>
            <p>
              We collect and use this personal data for the purposes described
              in the section ‘How and why we use your personal data’ below.
            </p>
            <h2>HOW YOUR PERSONAL DATA IS COLLECTED</h2>
            <p>We collect personal data from you: </p>
            <ul>
              <li>
                directly, when you enter or send us information, such as when
                you register for an account with us, contact us (including via
                email), send us feedback, use our Service, post material to our
                website and the Service, and complete customer surveys or
                participate in competitions via the Service, and
              </li>
              <li>
                indirectly, such as your browsing activity while on our website
                or while you are using the Service; we will usually collect
                information indirectly using the technologies explained in the
                section on ‘Cookies and other tracking technologies’ below.
              </li>
            </ul>
            <h2>HOW AND WHY WE USE YOUR PERSONAL DATA</h2>
            <p>
              Under data protection law, we can only use your personal data if
              we have a proper reason, eg:
            </p>
            <ul>
              <li>where you have given consent</li>
              <li>to comply with our legal and regulatory obligations</li>
              <li>
                for the performance of a contract with you or to take steps at
                your request before entering into a contract, or
              </li>
              <li>for our legitimate interests or those of a third party.</li>
              <p>
                A legitimate interest is when we have a business or commercial
                reason to use your information, so long as this is not
                overridden by your own rights and interests. We will carry out
                an assessment when relying on legitimate interests, to balance
                our interests against your own.
              </p>
            </ul>
          </div>
        </LicenseGrid>
      </div>
      <FooterAction />
    </div>
  );
};
export default PrivacyPolicy;
