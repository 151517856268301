import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
// import Skeleton from 'react-loading-skeleton';

// import { formatNumber } from 'utils';
import PriceInput from 'components/PriceInput';

import Modal from '../Modal';
import styles from '../Modal/common.module.scss';
import InputError from '../InputError';

const SellModal = ({
  visible,
  onClose,
  onSell,
  startPrice,
  payToken,
  confirming,
  approveContract,
  contractApproving,
  contractApproved,
}) => {
  const [price, setPrice] = useState('');
  const [focused, setFocused] = useState(false);
  const [selected, setSelected] = useState(payToken);

  const [inputError, setInputError] = useState(null);
  useEffect(() => {
    setPrice(startPrice > 0 ? startPrice.toString() : '');
  }, [visible]);
  useEffect(() => {
    if (payToken) {
      setSelected(payToken);
    }
  }, [payToken]);

  const handleSellItem = () => {
    onSell(selected, price);
  };

  const validateInput = () => {
    if (!price || price.length === 0 || parseFloat(price) == 0) return false;
    if (!selected) return false;
    return true;
  };

  return (
    <Modal
      visible={visible}
      title={startPrice > 0 ? 'Update Your Listing' : 'Sell Your Asset'}
      onClose={() => {
        onClose();
        setSelected(payToken);
        setPrice(startPrice);
      }}
      submitDisabled={
        contractApproving ||
        confirming ||
        (contractApproved && !validateInput()) ||
        inputError
      }
      submitLabel={
        contractApproved ? (
          confirming ? (
            <ClipLoader color="#FFF" size={16} />
          ) : startPrice > 0 ? (
            'Update Price'
          ) : (
            'List Your Asset'
          )
        ) : contractApproving ? (
          'Approving Your Asset'
        ) : (
          'Approve Your Asset'
        )
      }
      onSubmit={() =>
        contractApproved
          ? !confirming && validateInput()
            ? handleSellItem()
            : null
          : approveContract()
      }
      showCloseIcon={false}
    >
      <div className={styles.formGroup}>
        <div className={styles.formLabel}>Price</div>
        <div className={cx(styles.formInputCont, focused && styles.focused)}>
          {/* <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected}
            defaultValue={selected}
            label="Value"
            onChange={e => setSelected(e.target.value)}
            onOpen={e => {
              if (e.key !== 'Enter') {
                setOpenSelect(true);
              }
            }}
            onClose={() => {
              setOpenSelect(false);
            }}
            open={openSelect}
            className={styles.select}
            sx={{
              '.MuiSelect-select': {
                display: 'flex',
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          >
            {tokenAddresses.map(address => (
              <MenuItem
                value={address}
                key={address}
                className={styles.selectedToken}
              >
                <img
                  src={tokensByAddress[address]?.icon}
                  className={styles.tokenIcon}
                />
                <div className={styles.tokenSymbol}>
                  {tokensByAddress[address]?.symbol}
                </div>
              </MenuItem>
            ))}
          </Select> */}
          {/* <div className={styles.select}>
            <div className={styles.selectedToken}>
              <img src={selected?.icon} className={styles.tokenIcon} />
              <div className={styles.tokenSymbol}>{selected?.symbol}</div>
            </div>
          </div> */}
          <PriceInput
            className={styles.formInput}
            placeholder="0.00"
            decimals={selected?.decimals || 0}
            value={'' + price}
            onChange={setPrice}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            disabled={contractApproving || confirming}
            onInputError={setInputError}
          />
          {/* <div className={styles.usdPrice}>
            {!isNaN(selected?.price) && selected?.price !== null ? (
              `$${formatNumber(
                ((parseFloat(price) || 0) * selected?.price).toFixed(2)
              )}`
            ) : (
              <Skeleton width={100} height={24} />
            )}
          </div> */}
        </div>
        <InputError text={inputError} />
      </div>
    </Modal>
  );
};

export default SellModal;
