import React, { useEffect, useMemo, useState } from 'react';
import { set, upperFirst } from 'lodash';
import Header from 'components/header';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Footer from 'components/Footer';

import { useSelector } from 'react-redux';
import Modal from 'components/Modal';

import showToast from 'utils/toast';
import styles from './styles.module.scss';
import TokenInfo from './components/tokenInfo';
import CommunityInfo from './components/communityInfo';
import { useApi } from 'api';
import Identicon from 'components/Identicon';
import { useHistory } from 'react-router-dom';
import { StyledButton } from 'components/StyledComponents';
import { formatError, isEthereumAddress } from 'utils';
import Summary from './components/summary';
import { useFileChecking } from 'hooks/useFileChecking';
import { ASSET_TYPES } from 'constants/asset.constants';
import useContract from 'hooks/useContract';
import { TOKEN_REGISTRY_ABI } from 'contracts';
import { ethers } from 'ethers';
import { useAppKitAccount } from '@reown/appkit/react';
import cx from 'classnames';
import MetaTags from 'components/MetaTags';

const CustomCheckbox = styled(Checkbox)(() => ({
  color: 'lightgray',
  '&.Mui-checked': {
    color: 'var(--primary-color)',
  },
  '&.MuiCheckbox-indeterminate': {
    color: 'var(--primary-color)',
  },
}));

const AddToken = () => {
  const history = useHistory();
  const theme = useTheme();
  const [step, setStep] = useState(1);
  const { address: account } = useAppKitAccount();

  const {
    addPayToken,
    getPendingFiles,
    savePayTokenTxHash,
    fetchPayToken,
    removePayToken,
  } = useApi();
  const { getContract } = useContract();
  const { authToken } = useSelector(state => state.Auth);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isReLoad, setIsReload] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const [platformFee, setPlatformFee] = useState(0);
  const [openQuestion, setOpenQuestion] = useState(false);
  const resetTokenDetails = {
    asset: {},
    address: '',
    name: '',
    symbol: '',
    decimals: '',
    icon: '',
    community: {
      name: '',
      description: '',
      website: '',
      telegram: '',
      twitter: '',
      discord: '',
      instagram: '',
      tiktok: '',
    },
  };
  const [tokenDetails, setTokenDetails] = useState(resetTokenDetails);
  const [isPending, setIsPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const resetValidateError = { community: {} };
  const [validateError, setValidateError] = useState(resetValidateError);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [previewInView, setPreviewIsInView] = useState(true);
  const checkPreviewInView = () => {
    const element = document.getElementById('preview');
    if (element) {
      const rect = element.getBoundingClientRect();
      const isInView = rect.top > 60;

      setPreviewIsInView(state => (isInView === state ? state : isInView));
    }
  };

  const savingFields = JSON.stringify(tokenDetails);

  useEffect(() => {
    if (isReLoad) {
      window.localStorage.setItem('tokenItem', savingFields);
    }
  }, [savingFields, isReLoad]);

  // pending or failed assets
  const { checkedAssets } = useSelector(state => state.Notifications);

  const memoizedAsset = useMemo(
    () => (tokenDetails['asset'] ? [tokenDetails['asset']] : []),
    [tokenDetails['asset']]
  );
  const { errors, successes } = useFileChecking(
    memoizedAsset,
    [],
    checkedAssets
  );

  useEffect(() => {
    errors.forEach(message => {
      showToast('error', message);
    });
    successes.forEach(message => {
      showToast('success', message);
    });
  }, [errors, successes]);

  useEffect(() => {
    const getPlatformFee = async () => {
      const tokenRegistryContract = await getContract(
        process.env.REACT_APP_TOKEN_REGISTRY,
        TOKEN_REGISTRY_ABI
      );
      const fee = await tokenRegistryContract.platformFee();
      setPlatformFee(fee);
    };
    if (!platformFee) {
      getPlatformFee();
    }
  }, [step, platformFee]);

  const resetForm = (hard = false) => {
    setStep(1);
    // Do not remove asset
    setTokenDetails({
      ...resetTokenDetails,
      asset: hard ? {} : tokenDetails.asset,
    });
    setValidateError(resetValidateError);

    window.localStorage.removeItem('tokenItem');
  };

  const setupAsset = async assets => {
    for (let i = 0; i < assets.length; i++) {
      const asset = assets[i];
      const assetType = asset.assetType ? asset.assetType.name : asset.type;
      if (assetType === ASSET_TYPES.ICON) {
        updateTokenDetails('asset', asset);
        break;
      }
    }
  };

  useEffect(() => {
    (async authToken => {
      const { data } = await fetchPayToken(authToken);
      if (Object.keys(data).length > 0) {
        resetForm(true);
        setTokenDetails(data);
        setStep(2);
        setIsPending(true);
      } else {
        const savedItem = window.localStorage.getItem('tokenItem');
        if (savedItem) {
          const savedToken = JSON.parse(savedItem);
          setTokenDetails(savedToken);
        } else {
          resetForm();
        }
      }
      setIsReload(true);
    })(authToken);

    (async authToken => {
      const response = await getPendingFiles(authToken);
      if (response && response.data && response.data.length > 0) {
        const { data } = response;
        await setupAsset(data);
      }
    })(authToken);
  }, []);

  useEffect(() => {
    checkPreviewInView();
    document.addEventListener('scroll', checkPreviewInView);

    return () => {
      document.removeEventListener('scroll', checkPreviewInView);
    };
  }, []);

  const updateTokenDetails = (key, value) => {
    // checking max input length
    if (
      (key === 'asset' && typeof value === 'object') ||
      (typeof value === 'string' && value.length < 256) ||
      typeof value === 'number'
    ) {
      setTokenDetails(prevDetails => {
        const updatedDetails = { ...prevDetails };
        set(updatedDetails, Array.isArray(key) ? key : [key], value);
        return updatedDetails;
      });
    }
  };

  const validateInputs = () => {
    const errors = {
      community: {},
    };

    if (!tokenDetails.address.trim()) {
      errors.address = 'Token address is required';
    } else if (!isEthereumAddress(tokenDetails.address)) {
      errors.address = 'Invalid token address';
    }
    if (!tokenDetails.community.name.trim()) {
      errors.community.name = 'Community name is required';
    }
    if (!tokenDetails.community.description.trim()) {
      errors.community.description = 'Description is required';
    }

    const newErrors = { ...validateError, ...errors };
    setValidateError(newErrors);

    const noErrors = flattenObjectValues(newErrors).every(value => !value);
    return noErrors;
  };

  const handleBack = async () => {
    setStep(1);
  };

  const handleRemove = async () => {
    try {
      setLoading(true);
      const { data } = await removePayToken(authToken, tokenDetails.address);
      if (Object.keys(data).length > 0) {
        resetForm();
        setStep(1);
      } else {
        showToast('error', 'Something went wrong! Please try again');
      }
    } catch (error) {
      showToast('error', 'Something went wrong! Please try again');
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async () => {
    if (validateInputs()) {
      setStep(2);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const saveToken = async () => {
    setLoading(true);

    const model = {
      assetId: tokenDetails['asset']?.assetId,
      name: tokenDetails['name'],
      symbol: tokenDetails['symbol'],
      address: tokenDetails['address'],
      decimals: tokenDetails['decimals'],
      community: tokenDetails['community'],
    };
    if (!account) {
      showToast('error', 'Please connect your wallet');
      setLoading(false);

      return;
    }

    try {
      const { errors } = await addPayToken(authToken, model);

      if (!errors) {
        setIsPending(true);
        await saveTokenSC(model.address);
      } else {
        showToast('error', 'Something went wrong! Please try again');
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const saveTokenSC = async address => {
    try {
      const tokenRegistryContract = await getContract(
        process.env.REACT_APP_TOKEN_REGISTRY,
        TOKEN_REGISTRY_ABI
      );
      const tx = await tokenRegistryContract.add(address, {
        value: platformFee,
      });

      const txHash = tx?.hash;
      if (!txHash?.length) {
        showToast('error', 'Something went wrong! Please try again');
        return;
      }
      const { data } = await savePayTokenTxHash(authToken, address, txHash);
      if (Object.keys(data).length > 0) {
        showToast('success', 'New token is processing');
        resetForm(true);
        history.push('/profile/meme-tokens');
        return true;
      }
    } catch (error) {
      showToast('error', formatError(error));
      return false;
    }
  };

  const handleAddToken = async () => {
    if (!validateInputs()) {
      return;
    }

    // check icon
    if (!tokenDetails['asset']?.assetId && !tokenDetails['icon']) {
      setOpenQuestion(true);
    } else {
      await saveToken();
    }
  };

  const getTokenDisplayName = () => {
    const { name, symbol } = tokenDetails;

    if (!name && !symbol) {
      return <strong>Add Your Token</strong>;
    }

    if (name.trim().toLowerCase() === 'unknown') {
      return <strong>Unknown</strong>;
    }

    return (
      <Box sx={{ fontWeight: 600, color: 'var(--primary-text-color)' }}>
        {name} ({symbol})
      </Box>
    );
  };

  const flattenObjectValues = obj => {
    let values = [];
    Object.values(obj).forEach(value => {
      if (typeof value === 'object' && value !== null) {
        values = values.concat(flattenObjectValues(value));
      } else {
        values.push(value);
      }
    });
    return values;
  };

  const validationError = flattenObjectValues(validateError).some(Boolean);
  const communityFields = [
    'website',
    'telegram',
    'twitter',
    'discord',
    'instagram',
    'tiktok',
  ];

  const handleCancel = () => {
    setOpenQuestion(false);
  };

  const renderPreviewGrid = (id = null) => {
    return (
      <Box className={styles.photoContainer} id={id ? id : ''}>
        <Box className={styles.imageHolder}>
          <Box className={styles.previewTitle}>Preview</Box>
          <div className={styles.skeletonContainer}>
            <div className={styles.skeletonContent}>
              <Box className={styles.smallTitle}>Token</Box>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <Stack>
                  {tokenDetails['icon'] || tokenDetails['asset']?.fullUrl ? (
                    <img
                      src={
                        tokenDetails['icon'] || tokenDetails['asset']?.fullUrl
                      }
                      width="32"
                      height="32"
                      className={styles.avatarBig}
                    />
                  ) : (
                    <Identicon
                      account={tokenDetails['address'] || 'icon'}
                      size={36}
                      className={styles.avatarBigIdenticon}
                    />
                  )}
                </Stack>
                <Stack flexDirection="row" alignItems="center" gap={0.5}>
                  {getTokenDisplayName()}
                </Stack>
              </Stack>
              <Box className={styles.smallTitle} sx={{ mt: 2 }}>
                Community
              </Box>
              <Stack
                flexDirection="column"
                alignItems="flex-start"
                gap={0}
                pl={0.5}
              >
                <Box
                  sx={{ fontWeight: '600', color: 'var(--primary-text-color)' }}
                >
                  {tokenDetails['community']['name']}
                </Box>
                {tokenDetails['community']['description'] && (
                  <Box
                    sx={{
                      fontWeight: '400',
                      color: 'var(--secondary-text-color)',
                    }}
                  >
                    {tokenDetails['community']['description']}
                  </Box>
                )}
              </Stack>
              {communityFields.map(
                field =>
                  tokenDetails.community[field] && (
                    <React.Fragment key={field}>
                      <Box className={styles.smallTitle} sx={{ mt: 1 }}>
                        {field === 'twitter' ? 'X' : upperFirst(field)}
                      </Box>
                      <Stack
                        flexDirection="column"
                        alignItems="flex-start"
                        gap={0}
                        pl={0.5}
                      >
                        <Box sx={{ fontWeight: '400', color: '#1a73e8' }}>
                          <Link
                            href={tokenDetails.community[field]}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="hover"
                            color="inherit"
                          >
                            {tokenDetails.community[field]}
                          </Link>
                        </Box>
                      </Stack>
                    </React.Fragment>
                  )
              )}
              {step === 2 && (
                <>
                  {' '}
                  <Box
                    sx={{
                      my: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                    className={styles.text}
                  >
                    Price:{' '}
                    <Box sx={{ fontWeight: 500 }}>
                      {ethers.utils.formatEther(platformFee)} AVAX
                    </Box>
                  </Box>
                  <FormControlLabel
                    onClick={e => e.stopPropagation()}
                    label={
                      <Typography className={styles.text}>
                        Disclaimer
                      </Typography>
                    }
                    control={
                      <CustomCheckbox
                        checked={disclaimer}
                        onChange={() => setDisclaimer(prev => !prev)}
                      />
                    }
                  />{' '}
                  <Box
                    sx={{
                      mt: 2,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      '& .MuiButton-root': {
                        minWidth: '100px',
                        minHeight: '40px',
                      },
                    }}
                  >
                    {!isPending ? (
                      <StyledButton
                        secondary="true"
                        onClick={handleBack}
                        margin="0 5px"
                        disabled={loading}
                      >
                        Back
                      </StyledButton>
                    ) : (
                      <StyledButton
                        secondary="true"
                        onClick={handleRemove}
                        margin="0 5px"
                        disabled={loading}
                      >
                        Remove
                      </StyledButton>
                    )}

                    <StyledButton
                      onClick={
                        isPending
                          ? async () => {
                              setLoading(true);
                              await saveTokenSC(tokenDetails.address);
                              setLoading(false);
                            }
                          : handleAddToken
                      }
                      margin="0 5px"
                      disabled={validationError || !disclaimer || loading}
                    >
                      Add Token
                    </StyledButton>
                  </Box>
                </>
              )}
            </div>
          </div>
        </Box>
      </Box>
    );
  };
  return (
    <div className={styles.container}>
      {openQuestion && (
        <Modal
          visible
          showCloseIcon={false}
          onClose={handleCancel}
          submitDisabled={true}
          defaultPadding={false}
          small
        >
          <Box className={styles.modal}>
            <Stack gap={1}>
              <Box className={styles.content}>
                You have not selected a logo. Do you continue?
              </Box>

              <Box className={styles.buttons}>
                <StyledButton
                  sx={{ borderColor: 'white' }}
                  cancel="true"
                  onClick={handleCancel}
                  disabled={loading}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  onClick={() => {
                    saveToken();
                    setOpenQuestion(false);
                  }}
                  disabled={loading}
                >
                  Yes
                </StyledButton>
              </Box>
            </Stack>
          </Box>
        </Modal>
      )}
      <MetaTags
        title="Minti - Add Tokens"
        description="AI GENT Powered - Buy, Sell & Trade Content with Any Cryptocurrency"
      />
      <Header isSticky="sticky" />
      <div className={styles.body}>
        <div className={styles.ad}>Advertisement</div>
        <Grid container className={styles.content}>
          {step === 1 ? (
            <Grid item xs={12} md={7} className={styles.panel}>
              <div className={styles.title}>
                <p className={styles.titleContent}>Add New Token</p>
              </div>
              <TokenInfo
                asset={tokenDetails['asset']}
                setAsset={asset => {
                  updateTokenDetails('asset', asset);
                }}
                tokenDetails={tokenDetails}
                updateTokenDetails={updateTokenDetails}
                validateError={validateError}
                setValidateError={setValidateError}
                errors={errors}
              />
              <CommunityInfo
                tokenDetails={tokenDetails}
                updateTokenDetails={updateTokenDetails}
                validateError={validateError}
                setValidateError={setValidateError}
              />
              {validationError && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent={!isMobile ? 'flex-end' : 'center'}
                  alignItems="center"
                >
                  <div className={styles.errorText}>
                    The form is not complete. Please check your entry above and
                    try again
                  </div>
                </Box>
              )}
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  '& .MuiButton-root': {
                    minWidth: '100px',
                    minHeight: '40px',
                  },
                }}
              >
                <StyledButton
                  secondary="true"
                  onClick={resetForm}
                  margin="0 5px"
                >
                  Cancel
                </StyledButton>

                <StyledButton
                  onClick={handleSubmit}
                  margin="0 5px"
                  disabled={validationError}
                >
                  Submit
                </StyledButton>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} md={7} className={styles.panel}>
              <div className={styles.title}>
                <p className={styles.titleContent}>Review Your Token</p>
              </div>
              <Summary
                asset={tokenDetails['asset']}
                tokenDetails={tokenDetails}
              />
            </Grid>
          )}
          {!isMobile && (
            <div
              className={cx(
                styles.previewSticky,
                !previewInView && styles.showPreviewSticky
              )}
            >
              {renderPreviewGrid()}
            </div>
          )}
          {!isMobile ? (
            <Grid
              item
              xs={12}
              md={5}
              className={cx(
                styles.imageColumn,
                !previewInView && styles.previewHidden
              )}
            >
              {renderPreviewGrid('preview')}
            </Grid>
          ) : (
            <div className={styles.previewNFT}>
              <StyledButton
                width="100%"
                onClick={() => {
                  setOpenPreviewModal(prev => !prev);
                }}
              >
                {openPreviewModal ? 'Close preview' : 'Preview Your Assets'}
              </StyledButton>
            </div>
          )}
        </Grid>
        <Footer display />
      </div>
      <Modal
        visible={openPreviewModal}
        showCloseIcon={false}
        onClose={() => {
          if (openPreviewModal) {
            setOpenPreviewModal(false);
          }
        }}
        showTitle={false}
        sx={{ top: '120px' }}
      >
        {renderPreviewGrid()}
      </Modal>
    </div>
  );
};

export default AddToken;
