import React, { useState, useMemo, useEffect, useRef } from 'react';
import moment from 'moment';
import CustomDataGrid from 'components/CustomDataGrid';
import Identicon from 'components/Identicon';
import { useApi } from 'api';
import { useSelector } from 'react-redux';
import { Box, Chip } from '@mui/material';
import styles from './styles.module.scss';
import { StyledButton } from 'components/StyledComponents';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckIcon from '@mui/icons-material/Check';
import IconCopy from '@mui/icons-material/ContentCopy';
import toast from 'utils/toast';

const index = () => {
  const { getRefferals, sendReferralEmail } = useApi();
  const [email, setEmail] = useState('');
  const [sendingEmail, setSendingEmail] = useState(false);
  const { authToken, user } = useSelector(state => state.Auth);
  const [sortColumns, setSortColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [copied, setCopied] = useState(false);
  const inputRef = useRef(null);

  const confirmButton = useRef(null);
  useEffect(() => {
    if (confirmButton) {
      const listener = event => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
          event.preventDefault();
          confirmButton.current.click();
        }
      };
      document.addEventListener('keydown', listener);
      return () => {
        document.removeEventListener('keydown', listener);
      };
    }
  }, [confirmButton]);

  const handleCopyAddress = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
  useEffect(() => {
    (async () => {
      const { data: referrals } = await getRefferals(authToken);
      if (referrals) {
        setRows(referrals);
      }
    })();
  }, [authToken]);
  const columns = useMemo(
    () => [
      {
        key: 'name',
        name: 'Name',
        frozen: true,
        width: 170,
        renderCell: ({ row }) => {
          return (
            <div className={styles.nameContainer}>
              <div className={styles.avatarContainer}>
                {row.avatar ? (
                  <img src={row.avatar} className={styles.avatar} />
                ) : (
                  <Identicon
                    account={row.name}
                    size={40}
                    style={{
                      width: '40px',
                      height: '40px',
                    }}
                    className={styles.avatar}
                  />
                )}
              </div>
              <div>{row.name}</div>
            </div>
          );
        },
      },
      {
        key: 'status',
        name: 'Status',
        minWidth: 100,
        renderCell: ({ row }) => (
          <Chip
            avatar={
              <Box
                sx={{
                  width: '8px !important',
                  height: '8px !important',
                  borderRadius: '50%',
                  bgcolor: !row.isActive ? 'red' : 'green',
                  p: 0,
                }}
              />
            }
            variant="filled"
            sx={{
              bgcolor: !row.isActive
                ? '#ffeeee !important'
                : '#e5fdd4 !important',
              color: !row.isActive ? '#bd3d33' : 'green',
              fontWeight: 500,
              fontSize: '12px',
              height: '23px',
              pl: '5px',
            }}
            label={!row.isActive ? 'processing' : 'active'}
          />
        ),
      },
      {
        key: 'createdAt',
        name: 'Joined',
        minWidth: 100,
        renderCell: ({ row }) => (
          <div>{moment(row.createdAt).format('DD MMMM YYYY')}</div>
        ),
      },
      {
        key: 'bonusPaid',
        name: 'Bonus Paid',
        minWidth: 150,
        renderCell: ({ row }) => (
          <div>{row.bonusPaid ? `${row.bonusPaid} MINT` : ''}</div>
        ),
      },
    ],
    []
  );

  const getComparator = () => {
    return (a, b) => (a > b ? 1 : a === b ? 0 : -1);
  };
  const referralLink = `${
    window.location.origin
  }/auth/sign-up?code=${user.name.toLowerCase().replaceAll(' ', '+')}`;

  const handleInvite = async () => {
    setSendingEmail(true);
    let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(regex)) {
      try {
        const { data, errors } = await sendReferralEmail(authToken, email);
        if (data === true) {
          toast('success', 'Email was sent');
        } else {
          if (errors.length > 0) {
            toast('error', errors[0].message);
          } else {
            toast('error', 'Please try again');
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast('error', 'Email is invalid');
    }
    setEmail('');
    inputRef.current.focus();
    setSendingEmail(false);
  };
  const sortedRows = useMemo(() => {
    if (rows.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Referrals
        <div className={styles.subTitle}>
          Two-Tier Referral Rewards: Earn MINTI token rewards when your friends
          sign up, and also when their friends join ! Grow your Minti network
          and get Minti !
        </div>
      </div>

      <div className={styles.invite}>
        <div className={styles.inviteTitle}>
          Invite your friends and earn MINT
        </div>
        <div className={styles.inviteContainer}>
          <div className={styles.inviteTitle}>By sending email:</div>
          <div className={styles.inviteAction}>
            <input
              placeholder="Enter the email address"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
              className={styles.input}
              ref={inputRef}
            />
            <StyledButton
              onClick={handleInvite}
              disabled={sendingEmail}
              className={cx(
                styles.submitButton,
                sendingEmail && styles.disabled
              )}
              ref={confirmButton}
            >
              {sendingEmail ? <ClipLoader color="#FFF" size={16} /> : 'Invite'}
            </StyledButton>
          </div>
        </div>
        <div className={styles.inviteContainer}>
          <div className={styles.inviteTitle}>By sending link:</div>
          <div className={styles.inviteAction}>
            <Box
              sx={{
                display: 'flex',
                gap: '5px',
              }}
            >
              <CopyToClipboard text={referralLink} onCopy={handleCopyAddress}>
                <StyledButton
                  endIcon={
                    copied ? (
                      <CheckIcon sx={{ color: 'darkblue', mr: '5px' }} />
                    ) : (
                      <IconCopy />
                    )
                  }
                  className={styles.link}
                >
                  {referralLink}
                </StyledButton>
              </CopyToClipboard>
            </Box>
          </div>
        </div>
      </div>
      <div className={styles.gridContainer}>
        <CustomDataGrid
          mainHeader="Referral Members"
          subHeader={rows.length === 1 ? '1 user' : `${rows.length} users`}
          columns={columns}
          setRows={setRows}
          setSortColumns={setSortColumns}
          sortColumns={sortColumns}
          sortedRows={sortedRows}
          rowHeight={60}
        />
      </div>
    </div>
  );
};

export default index;
