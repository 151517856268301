import React, { useState, useEffect, useRef, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import { useApi } from 'api';
import { useSelector } from 'react-redux';
import { useHistory, useParams, Link, useLocation } from 'react-router-dom';
import { isAudioFormat } from 'utils';

import {
  Grid,
  Backdrop,
  Skeleton,
  styled,
  // useTheme,
  useMediaQuery,
  Box,
} from '@mui/material';

import { StyledButton } from 'components/StyledComponents';
import Header from 'components/header';
import styles from '../styles.module.scss';
import {
  InfoOutlined,
  AppRegistrationOutlined,
  MovingOutlined,
  AttachMoneyOutlined,
  SellOutlined,
  AttachmentOutlined,
} from '@mui/icons-material';
import defaultAvatar from 'assets/imgs/default_avatar.png';
import pdfPreviewIcon from 'assets/svgs/prfPreviewIcon.svg';
import Identicon from 'components/Identicon';
import { useResizeDetector } from 'react-resize-detector';
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  CartesianGrid,
  Line,
} from 'recharts';
import {
  useNFTContract,
  useSalesContract,
  useAuctionContract,
  ERC721_CONTRACT_ABI,
  SALES_CONTRACT_ABI,
  AUCTION_CONTRACT_ABI,
} from 'contracts';
import { getReadContract } from 'contracts/readContract';
import { Contracts } from 'constants/networks';

import Carousel from 'components/Carousel';
import SellModal from 'components/SellModal';
import DownloadModal from 'components/DownloadModal';
import OfferModal from 'components/OfferModal';
import AuctionModal from 'components/AuctionModal';
import BidModal from 'components/BidModal';

import { getContractTypeById } from 'constants/contracts.constants';
import { useAppKitAccount } from '@reown/appkit/react';
import { Description, NftDetailsButtons, PropertyItem } from '../components';
import 'react-alice-carousel/lib/alice-carousel.css';
import { NFT_STATUSES_CODES } from 'constants/NFTStatuses.constants';
import {
  formatNumber,
  shortenAddress,
  formatError,
  formatDuration,
  uuidv4,
} from 'utils';
import showToast from 'utils/toast';
import Comments from './components/Comments';
import ActionMenu from './components/ActionMenu';

import { ethers } from 'ethers';

import { totalCommentsCount, totalLikes } from './utils';
import ShareModal from 'components/ShareModal';
import IconLoading from 'assets/icons/iconLoading';
import CarouselModal from 'components/CarouselModal';
import { contractTypeIds } from 'constants/contracts.constants';
import { ASSET_TYPES } from 'constants/asset.constants';
import MasonaryNFTs from 'components/MasonaryNFTs';
import KYCModal from 'components/KYCModal';
import FooterAction from 'components/FooterAction';
import MetaTags from 'components/MetaTags';

const StyledBackdrop = styled(Backdrop)(() => ({
  backgroundColor: 'rgba(35, 35, 52, 0.9)',
  zIndex: 9999,
}));

const Offer = ({
  user,
  deadline,
  price,
  token,
  salesContractApproved,
  offerAccepting,
  salesContractApproving,
  handleAcceptOffer,
  handleApproveSalesContract,
  isMine,
  authToken,
}) => {
  const now = new Date(Date.now());
  const endDate = new Date(deadline);
  return (
    <div className={styles.offerContainer}>
      <div className={styles.name}>
        <div className={styles.nameTitle}>Offered by</div>
        <div className={styles.nameContent}>
          <div className={styles.avatar}>
            {user?.avatar ? (
              <img src={`${user.avatar}`} className={styles.avatarImg} />
            ) : (
              <Identicon
                account={user.userId}
                size={20}
                className={styles.avatarImg}
              />
            )}
          </div>
          {user?.name}
        </div>
      </div>
      <div className={styles.offer}>
        <div className={styles.offerTitle}>
          {now.getTime() > endDate.getTime()
            ? 'Expired'
            : endDate.toLocaleDateString('en-US')}
        </div>
        <div className={styles.offerPrice}>{`${price} ${token?.symbol}`}</div>
      </div>
      <div className={styles.action}>
        {now.getTime() < endDate.getTime() && isMine && (
          <StyledButton
            onClick={
              salesContractApproved
                ? () =>
                    handleAcceptOffer(
                      authToken,
                      user?.userWallets[0]?.address,
                      token,
                      price
                    )
                : () => handleApproveSalesContract()
            }
          >
            {!salesContractApproved ? (
              salesContractApproving ? (
                <ClipLoader color="#FFF" size={16} />
              ) : (
                'Approve'
              )
            ) : offerAccepting ? (
              <ClipLoader color="#FFF" size={16} />
            ) : (
              'Accept'
            )}
          </StyledButton>
        )}
      </div>
    </div>
  );
};

const TradeHistory = ({ buyer, seller, price, token, date }) => {
  const convertedDate = Math.floor(new Date(date) / 1000);
  return (
    <div className={styles.tradeContainer}>
      <div className={styles.tradeContent}>
        <div className={styles.name}>
          <div className={styles.nameTitle}>From</div>
          <div className={styles.nameContent}>
            <div className={styles.avatar}>
              {seller.avatar ? (
                <img src={`${seller.avatar}`} className={styles.avatarImg} />
              ) : (
                <Identicon
                  account={seller.name}
                  size={20}
                  className={styles.avatarImg}
                />
              )}
            </div>
            {seller.name}
          </div>
        </div>
        <div className={styles.name}>
          <div className={styles.nameTitle}>To</div>
          <div className={styles.nameContent}>
            <div className={styles.avatar}>
              {buyer.avatar ? (
                <img src={`${buyer.avatar}`} className={styles.avatarImg} />
              ) : (
                <Identicon
                  account={buyer.name}
                  size={20}
                  className={styles.avatarImg}
                />
              )}
            </div>
            {buyer.name}
          </div>
        </div>
        <div className={styles.name}>
          <div className={styles.nameTitle}>Price</div>
          <div className={styles.nameContent}>
            {token.icon && (
              <img src={`${token.icon}`} className={styles.avatarImg} />
            )}
            {`${price} ${!token.icon ? token.symbol : ''}`}
          </div>
        </div>
        <div className={styles.name}>
          <div className={styles.date}>
            ({`${formatDuration(convertedDate)} ago`})
          </div>
        </div>
      </div>
    </div>
  );
};

const index = () => {
  const { id: tokenName, action } = useParams();
  const { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 1000,
  });
  const history = useHistory();
  const location = useLocation();

  const isMobile = useMediaQuery('(max-width: 900px)');

  const { authToken, user } = useSelector(state => state.Auth);
  const userWallet = user?.userWallets?.at(0);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contractTokenID, setContractTokenID] = useState();
  const [isMine, setIsMine] = useState(null);
  const [owner, setOwner] = useState();
  const [createdNft, setCreatedNft] = useState({});
  const [isLiked, setIsLiked] = useState(false);
  const [totalShare, setTotalShare] = useState(0);
  const [auction, setAuction] = useState();
  const [listing, setListing] = useState();
  const [hasMyOffer, setHasMyOffer] = useState();
  const [offers, setOffers] = useState([]);
  const [tradeHistory, setTradeHistory] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [sellModalVisible, setSellModalVisible] = useState(false);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [offerModalVisible, setOfferModalVisible] = useState(false);
  const [auctionModalVisible, setAuctionModalVisible] = useState(false);
  const [bidModalVisible, setBidModalVisible] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);

  const [kycModalVisible, setKycModalVisible] = useState(false);

  const [downloadFiles, setDownloadFiles] = useState([]);
  const [thumbnailUrl, setThumbnailUrl] = useState();
  const [thumbnailVideoUrl, setThumbnailVideoUrl] = useState();

  const [bid, setBid] = useState(null);
  const [posterUrl, setPosterUrl] = useState();
  const [promo, setPromo] = useState([]);
  const [auctionContractApproved, setAuctionContractApproved] = useState(false);
  const [auctionContractApproving, setAuctionContractApproving] = useState(
    false
  );
  const [contractFetching, setContractFetching] = useState();
  const [bidPlacing, setBidPlacing] = useState(false);
  // const [bidWithdrawing, setBidWithdrawing] = useState(false);

  const [auctionCanceling, setAuctionCanceling] = useState(false);
  const [auctionStarting, setAuctionStarting] = useState(false);
  const [offerAccepting, setOfferAccepting] = useState(false);
  const [listingConfirming, setListingConfirming] = useState(false);
  const [cancelingListing, setCancelingListing] = useState(false);
  const [buyingItem, setBuyingItem] = useState(false);
  const [salesContractApproved, setSalesContractApproved] = useState(false);
  const [salesContractApproving, setSalesContractApproving] = useState(false);
  const [erc20ContractApproving, setERC20ContractApproving] = useState(false);
  const [erc20ContractApproved, setERC20ContractApproved] = useState(false);
  const [offerConfirming, setOfferConfirming] = useState(false);
  const [showComments, setShowComments] = useState(true);
  const [firstCommentsLoad, setFirstCommentsLoad] = useState(true);
  const [sourceFileURL, setSourceFileURL] = useState();
  const [showSourceFile, setShowSourceFile] = useState(true);
  const prevSalesContract = useRef(null);
  const prevAuctionContract = useRef(null);

  const {
    fetchCreatedNftByName,
    getNftTradeHistories,
    getNftImageOrAsset,
    explorerUrl,
    getUserAccountDetails,
    getUserByWalletAddress,
    increaseViewCount,
    getNftOffers,
    addNftFlag,
    getTotalShare,
    requestReprocessing,
    getTransaction,
    verifyValidTradeAmount,
    toggleLikeItem,
  } = useApi();

  const {
    getSalesContract,
    cancelListing,
    buyItemERC20,
    listItem,
    updateListing,
    createOffer,
    cancelOffer,
    acceptOffer,
  } = useSalesContract();

  const {
    getAuctionContract,
    cancelAuction,
    // resultAuction,
    createAuction,
    getHighestBidder,
    placeBid,
    updateAuctionStartTime,
    updateAuctionEndTime,
    updateAuctionReservePrice,
    // withdrawBid,
  } = useAuctionContract();

  const { getERC20Contract, getERC721Contract } = useNFTContract();
  const { address: account } = useAppKitAccount();

  const priceHistory = [...tradeHistory].reverse().map(history => {
    const saleDate = new Date(history.saleDate);
    return {
      date: `${saleDate.getFullYear()}/${saleDate.getMonth() +
        1}/${saleDate.getDate()}`,
      price: history.price,
      amt: 2100,
    };
  });
  console.log('priceHistory: ', priceHistory);
  const downloadAvailable = useMemo(() => {
    if (
      createdNft?.artistId !== user.userId &&
      createdNft?.contractTypeId === contractTypeIds.NONEXCLUSIVE
    ) {
      if (listing || auction) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }, [auction, listing, isMine, createdNft?.contractTypeId]);

  const code = new URLSearchParams(location.search).get('code');
  const contractType = createdNft?.contractType
    ? getContractTypeById(createdNft?.contractType?.contractTypeId)
    : undefined;

  const auctionStarted =
    auction && new Date(Date.now()).getTime() / 1000 >= auction.startTime;

  const auctionEnded =
    auction && auction.endTime <= new Date(Date.now()).getTime() / 1000;

  const handleNftFlagged = async violation => {
    if (!authToken) return;

    const model = {
      nftId: createdNft.nftId,
      violation,
    };

    await addNftFlag(model, authToken);
    showToast('success', 'Thank you for your feedback!');
  };

  const asyncNft = async () => {
    setLoading(true);
    const response = await fetchCreatedNftByName(
      authToken,
      tokenName.replaceAll('+', ' ')
    );
    if (response.data && Object.keys(response.data).length > 0) {
      const nft = response.data;
      if (nft.nftStatusId === NFT_STATUSES_CODES.PREMINT) {
        history.push(`/draft/${nft.nftId}`, { from: 'explore' });
      }
      if (nft.nftStatusId !== NFT_STATUSES_CODES.MINTED) {
        history.push(`/pending/${nft.nftId}`, { from: 'explore' });
      }

      const tempAsset = [];
      const sourceFile = [];

      let watermarkedThumbnailURL = '';
      let selectedImage;
      for (let i = 0; i < nft.nftAssets.length; i++) {
        const asset = nft.nftAssets[i];

        if (asset.assetType.name === ASSET_TYPES.PHOTOS) {
          tempAsset.push(asset.url);
        } else if (asset.assetType.name === ASSET_TYPES.THUMBNAIL_VIDEO) {
          setThumbnailVideoUrl(asset.url);
        } else if (asset.assetType.name === ASSET_TYPES.THUMBNAIL_IMAGE) {
          selectedImage = asset.parentId;
          setThumbnailUrl(asset.url);
        } else if (
          asset.assetType.name === ASSET_TYPES.WATERMARKED_THUMBNAIL_IMAGE
        ) {
          watermarkedThumbnailURL = asset.url;
        } else if (
          asset.assetType.name === ASSET_TYPES.WATERMARKED_SOURCE_FILE
        ) {
          sourceFile.push(asset);
        } else if (asset.assetType.name === 'watermarkedThumbnailVideo') {
          sourceFile.push(asset);
        } else if (asset.assetType.name === 'downsizedAudio') {
          sourceFile.push(asset);
        }
      }

      if (sourceFile.length > 0 && selectedImage) {
        const foundChosenImageIndex = sourceFile.findIndex(
          el => el.parentId === selectedImage
        );
        const chosenFile = sourceFile[foundChosenImageIndex];
        sourceFile.splice(foundChosenImageIndex, 1);
        watermarkedThumbnailURL = chosenFile.url;
      }
      const sourceFileURL = sourceFile.map(item => item.url);
      setSourceFileURL(sourceFileURL);

      setPosterUrl(watermarkedThumbnailURL);

      setPromo(tempAsset);

      setAssets(
        !watermarkedThumbnailURL ||
          (sourceFileURL.length === 1 && isAudioFormat(sourceFileURL[0]))
          ? [...sourceFileURL]
          : [watermarkedThumbnailURL, ...sourceFileURL]
      );

      setCreatedNft(nft);
      if (nft.nftContractId) {
        setContractTokenID(nft.nftContractId);
      }
      // init total comment counts
      totalCommentsCount.value = nft.comments || 0;
      totalLikes.value = nft.likes || 0;
      setIsLiked(Boolean(nft.isLiked));
      console.log(nft.isFavorite);
    } else {
      history.replace('/404');
    }
    setLoading(false);
  };

  const toggleShowSourceFile = async () => {
    setAssets(
      showSourceFile
        ? [...promo]
        : [...(posterUrl ? [posterUrl] : []), ...sourceFileURL]
    );
    setShowSourceFile(prev => !prev);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const renderFiles = files => {
    return files?.map(file => {
      return (
        <a
          href={file.url}
          key={file.asset_id}
          className={styles.documentLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src={pdfPreviewIcon}
            alt={file.name}
            className={styles.documentIcon}
          />
        </a>
      );
    });
  };

  const handleAcceptOffer = async (authToken, address, payToken, price) => {
    if (offerAccepting) return;
    if (!account) {
      showToast('error', 'Please connect your wallet');
      return;
    }

    try {
      const { data } = await verifyValidTradeAmount(
        payToken.address,
        price,
        authToken
      );

      if (!data) {
        setKycModalVisible(true);
        return;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      setOfferAccepting(true);
      const tx = await acceptOffer(Contracts.artion, contractTokenID, address);
      if (tx) {
        await tx.wait();
      }

      setOfferAccepting(false);

      showToast('success', 'Offer accepted!');

      setOwner(address);

      setOffers(prev =>
        prev.filter(offer => offer.creator?.userWallets[0]?.address !== address)
      );
    } catch (error) {
      showToast('error', formatError(error, 'Accept Offer'));
      setOfferAccepting(false);
    }
  };

  const handleListItem = async (token, _price) => {
    if (listingConfirming) return;
    if (!account) {
      showToast('error', 'Please connect your wallet');
      setSellModalVisible(false);
      return;
    }
    try {
      const { data } = await verifyValidTradeAmount(
        token.address,
        _price,
        authToken
      );
      if (!data) {
        setKycModalVisible(true);
        setSellModalVisible(false);
        return;
      }
    } catch (error) {
      console.log(error);
    }
    try {
      setListingConfirming(true);
      const price = ethers.utils.parseUnits(_price, token.decimals);
      const tx = await listItem(
        Contracts.artion,
        contractTokenID,
        '1',
        token.address,
        price,
        Math.floor(new Date().getTime() / 1000)
      );
      if (tx) {
        await tx.wait();
      }

      setSellModalVisible(false);
    } catch (err) {
      console.log(err);

      showToast('error', formatError(err));
    } finally {
      setListingConfirming(false);
    }
  };

  const handleUpdateListing = async (token, _price) => {
    if (listingConfirming) return;
    if (!account) {
      showToast('error', 'Please connect your wallet');
      return;
    }
    try {
      const { data } = await verifyValidTradeAmount(
        listing.token.address,
        listing.price,
        authToken
      );
      if (!data) {
        setKycModalVisible(true);
        setSellModalVisible(false);

        return;
      }
    } catch (error) {
      console.log(error);
    }
    try {
      setListingConfirming(true);
      const price = ethers.utils.parseUnits(_price, token.decimals);
      const tx = await updateListing(
        Contracts.artion,
        contractTokenID,
        token.address === '' ? ethers.constants.AddressZero : token.address,
        price
      );
      if (tx) {
        await tx.wait();
      }
      setListingConfirming(false);

      setSellModalVisible(false);
    } catch (e) {
      console.log(e);
      setListingConfirming(false);
      showToast('error', formatError(e));
    }
  };

  const handleCancelListing = async () => {
    if (cancelingListing) return;
    if (!account) {
      showToast('error', 'Please connect your wallet');
      return;
    }
    setCancelingListing(true);
    try {
      await cancelListing(Contracts.artion, contractTokenID);
      setSalesContractApproved(false);
    } catch (e) {
      showToast('error', formatError(e));
      console.log(e);
    }
    setCancelingListing(false);
  };

  const handleCancelOffer = async () => {
    if (offerConfirming) return;
    if (!account) {
      showToast('error', 'Please connect your wallet');
      return;
    }
    try {
      setOfferConfirming(true);

      const tx = await cancelOffer(Contracts.artion, contractTokenID);
      if (tx) {
        await tx.wait();
      }
    } catch (error) {
      showToast('error', formatError(error, 'Cancel Offer'));
    } finally {
      setOfferConfirming(false);
    }
  };

  const handleApproveERC20Contract = async (token, amount, setModal) => {
    if (!account) {
      showToast('error', 'Please connect your wallet');
      setModal(false);
      return;
    }
    setERC20ContractApproving(true);
    try {
      const erc20 = await getERC20Contract(token.address);

      const price = ethers.utils.parseUnits(amount, token.decimals);

      const balance = await erc20.balanceOf(account);
      if (parseFloat(balance) < parseFloat(price)) {
        showToast('error', `Do not have enough USD`);
        setERC20ContractApproving(false);
        return;
      }
      const allowance = await erc20.allowance(account, Contracts.sales);
      if (allowance < price) {
        const tx = await erc20.approve(Contracts.sales, price);
        if (tx) {
          await tx.wait();
        }
      }
      setERC20ContractApproved(true);
    } catch (e) {
      console.log(e);
    } finally {
      setERC20ContractApproving(false);
    }
  };

  const handleApproveSalesContract = async () => {
    if (!account) {
      showToast('error', 'Please connect your wallet');
      setSellModalVisible(false);
      return;
    }
    setSalesContractApproving(true);

    try {
      const contract = await getERC721Contract(Contracts.artion);
      const tx = await contract.setApprovalForAll(Contracts.sales, true);
      if (tx) {
        await tx.wait();
      }
      setSalesContractApproved(true);
    } catch (e) {
      console.log(e);
    } finally {
      setSalesContractApproving(false);
    }
  };

  const handleApproveAuctionContract = async () => {
    if (!account) {
      showToast('error', 'Please connect your wallet');
      setAuctionModalVisible(false);
      return;
    }
    setAuctionContractApproving(true);

    try {
      const contract = await getERC721Contract(Contracts.artion);
      const tx = await contract.setApprovalForAll(Contracts.auction, true);
      if (tx) {
        await tx.wait();
      }
      setAuctionContractApproved(true);
    } catch (e) {
      console.log(e);
    } finally {
      setAuctionContractApproving(false);
    }
  };

  const handleDownloadFile = async () => {
    if (!downloadAvailable) {
      showToast(
        'error',
        'You need to list / start auctioning the asset before downloading'
      );
      return;
    }
    const { data, errors: downloadErrs } = await getNftImageOrAsset(
      createdNft.nftId,
      authToken
    );
    if (downloadErrs) {
      if (downloadErrs[0].message?.includes('download limit')) {
        showToast(
          'error',
          formatError({
            data: {
              message:
                'You reached the maximum of downloads allowed for this file.',
            },
          })
        );
      }

      return;
    }

    setDownloadFiles(data.map(item => ({ ...item, progress: 0 })));

    setDownloadModalVisible(true);
  };

  const handleMakeOffer = async (token, _price, quantity, endTime) => {
    if (offerConfirming) return;
    if (!account) {
      showToast('error', 'Please connect your wallet');
      setOfferModalVisible(false);
      return;
    }
    try {
      const { data } = await verifyValidTradeAmount(
        token.address,
        _price,
        authToken
      );
      if (!data) {
        setKycModalVisible(true);
        setOfferModalVisible(false);

        return;
      }
    } catch (error) {
      console.log(error);
    }

    if (
      !owner ||
      (owner &&
        userWallet?.address &&
        owner.toLowerCase() === userWallet.address.toLowerCase())
    ) {
      return;
    }

    try {
      setOfferConfirming(true);
      const price = ethers.utils.parseUnits(_price, token.decimals);
      const deadline = Math.floor(endTime.getTime() / 1000);
      const amount = parseFloat(price) * quantity;

      const erc20 = await getERC20Contract(token.address);
      const balance = await erc20.balanceOf(account);

      if (balance < amount) {
        showToast('error', `Do not have enough USD`);
        setOfferConfirming(false);
        return;
      }

      const allowance = await erc20.allowance(account, Contracts.sales);
      if (allowance < amount) {
        setERC20ContractApproved(false);
        return;
      }

      const tx = await createOffer(
        Contracts.artion,
        contractTokenID,
        token.address,
        quantity,
        price,
        deadline
      );
      if (tx) {
        await tx.wait();
      }

      setOfferModalVisible(false);
    } catch (e) {
      setOfferConfirming(false);
      showToast('error', formatError(e, 'Make Offer'));
      console.log(e);
    } finally {
      setOfferConfirming(false);
    }
  };

  const handleStartAuction = async (token, _price, _startTime, _endTime) => {
    if (!account) {
      showToast('error', 'Please connect your wallet');
      setAuctionModalVisible(false);
      return;
    }

    try {
      const { data } = await verifyValidTradeAmount(
        token.address,
        _price,
        authToken
      );
      if (!data) {
        setKycModalVisible(true);
        setAuctionModalVisible(false);
        return;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      setAuctionStarting(true);

      const price = ethers.utils.parseUnits(_price, token.decimals);
      const startTime = Math.floor(_startTime.getTime() / 1000);
      const endTime = Math.floor(_endTime.getTime() / 1000);

      const tx = await createAuction(
        Contracts.artion,
        contractTokenID,
        token.address === '' ? ethers.constants.AddressZero : token.address,
        price,
        startTime,
        endTime
      );
      if (tx) {
        await tx.wait();
      }
      setAuctionStarting(false);
      setAuctionModalVisible(false);
    } catch (error) {
      console.log(error);
      showToast('error', formatError(error, 'Start Auction'));
      setAuctionStarting(false);
    }
  };

  const handleUpdateAuction = async (token, _price, _startTime, _endTime) => {
    if (!auction) return;
    if (!account) {
      showToast('error', 'Please connect your wallet');
      setAuctionModalVisible(false);
      return;
    }
    try {
      setAuctionStarting(true);

      if (parseFloat(_price) !== auction.reservePrice) {
        try {
          const { data } = await verifyValidTradeAmount(
            token.address,
            _price,
            authToken
          );
          if (!data) {
            setKycModalVisible(true);
            setAuctionModalVisible(false);
            return;
          }
        } catch (error) {
          console.log(error);
        }

        const price = ethers.utils.parseUnits(_price, token.decimals);
        await updateAuctionReservePrice(
          Contracts.artion,
          contractTokenID,
          price
        );
      }

      const startTime = Math.floor(_startTime.getTime() / 1000);
      if (startTime !== auction.startTime) {
        await updateAuctionStartTime(
          Contracts.artion,
          contractTokenID,
          startTime
        );
      }

      const endTime = Math.floor(_endTime.getTime() / 1000);
      if (endTime !== auction.endTime) {
        await updateAuctionEndTime(Contracts.artion, contractTokenID, endTime);
      }

      setAuctionStarting(false);
      setAuctionModalVisible(false);
    } catch (error) {
      console.log(error);
      showToast('error', formatError(error, 'Update Auction'));
      setAuctionStarting(false);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleCancelAuction = async () => {
    if (auctionCanceling) return;
    if (!account) {
      showToast('error', 'Please connect your wallet');
      setAuctionModalVisible(false);
      return;
    }
    try {
      setAuctionCanceling(true);
      const tx = await cancelAuction(Contracts.artion, contractTokenID);
      if (tx) {
        await tx.wait();
      }
      setAuctionContractApproved(false);
    } catch (err) {
      showToast('error', formatError(err, 'Cancel Auction'));
      console.log(err);
    } finally {
      setAuctionCanceling(false);
    }
  };

  // const handleResultAuction = async () => {
  //   if (auctionCanceling) return;
  //   if (!account) {
  //     showToast('error', 'Please connect your wallet');
  //     setAuctionModalVisible(false);
  //     return;
  //   }
  //   if (bid === null) {
  //     showToast('info', 'No bids were placed!');
  //     return;
  //   }

  //   try {
  //     setAuctionCanceling(true);
  //     await resultAuction(Contracts.artion, contractTokenID);
  //     setAuctionCanceling(false);
  //     setOwner(bid.bidder);

  //     showToast('success', 'Auction resulted!');
  //   } catch (error) {
  //     console.log(error);
  //     showToast('error', formatError(error, 'Conclude Auction'));
  //     setAuctionCanceling(false);
  //   }
  // };

  const handlePlaceBid = async _price => {
    if (bidPlacing) return;
    if (!account) {
      showToast('error', 'Please connect your wallet');
      setBidModalVisible(false);
      return;
    }
    try {
      setBidPlacing(true);

      const { token } = auction;
      const price = ethers.utils.parseUnits(_price, token.decimals);
      if (token.address !== '') {
        const erc20 = await getERC20Contract(token.address);
        const balance = await erc20.balanceOf(account);
        if (balance.lt(price)) {
          showToast('error', `Do not have enough USD`);

          setBidPlacing(false);
          return;
        }
        const auctionContract = await getAuctionContract();
        const allowance = await erc20.allowance(
          account,
          auctionContract.address
        );
        if (allowance.lt(price)) {
          const tx = await erc20.approve(auctionContract.address, price);
          if (tx) {
            await tx.wait();
          }
        }
      }
      const tx = await placeBid(
        Contracts.artion,
        contractTokenID,
        token.address === '' ? ethers.constants.AddressZero : token.address,
        price
      );
      if (tx) {
        await tx.wait();
      }

      showToast('success', 'Bid placed successfully!');

      setBidPlacing(false);
      setBidModalVisible(false);
    } catch (error) {
      showToast('error', formatError(error, 'Place Bid'));
      setBidPlacing(false);
    }
  };

  // const handleWithdrawBid = async () => {
  //   if (bidWithdrawing) return;
  //   if (!account) {
  //     showToast('error', 'Please connect your wallet');
  //     setBidModalVisible(false);
  //     return;
  //   }
  //   try {
  //     setBidWithdrawing(true);
  //     const tx = await withdrawBid(Contracts.artion, contractTokenID);
  //     if (tx) {
  //       await tx.wait();
  //     }
  //     setBidWithdrawing(false);
  //     showToast('success', 'You have withdrawn your bid!');
  //   } catch (error) {
  //     showToast('error', formatError(error, 'Withdraw Bid'));
  //     setBidWithdrawing(false);
  //   }
  // };

  const handleBuyItem = async listing => {
    if (buyingItem) return;
    if (!account) {
      showToast('error', 'Please connect your wallet');
      return;
    }
    try {
      const { data } = await verifyValidTradeAmount(
        listing.token.address,
        listing.price,
        authToken
      );
      if (!data) {
        setKycModalVisible(true);
        return;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      setBuyingItem(true);
      const { data } = await getTransaction(createdNft.nftId);
      if (Object.keys(data).length > 0) {
        showToast('error', 'Someone has bought the asset');
        return;
      }

      const _price = listing.price * listing.quantity;

      const erc20 = await getERC20Contract(listing.token.address);
      const price = ethers.utils.parseUnits(
        _price.toString(),
        listing.token.decimals
      );

      const salesContract = await getSalesContract();
      const balance = await erc20.balanceOf(account);

      if (balance.lt(price)) {
        showToast('error', `Do not have enough USD`);

        setOfferConfirming(false);
        setBuyingItem(false);

        return;
      }

      const allowance = await erc20.allowance(account, salesContract.address);
      if (allowance.lt(price)) {
        const tx = await erc20.approve(salesContract.address, price);
        if (tx) {
          await tx.wait();
        }
      }

      const tx = await buyItemERC20(
        Contracts.artion,
        contractTokenID,
        listing.token.address,
        listing.owner.address,
        0
      );

      if (tx) {
        await tx.wait();
      }

      setOwner(account);
    } catch (error) {
      console.log(error);
      showToast('error', formatError(error, 'Buy Asset'));
    }
    setBuyingItem(false);
  };

  const getBid = async auction => {
    try {
      if (auction) {
        const bid = await getHighestBidder(Contracts.artion, contractTokenID);

        bid.bid = parseFloat(
          ethers.utils.formatUnits(bid.bid, auction.token.decimals)
        );
        if (bid.bid !== 0) {
          setBid(bid);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchNFTDetails = async contractTokenID => {
    if (contractTokenID) {
      const contract = getReadContract(Contracts.artion, ERC721_CONTRACT_ABI);
      try {
        if (contract) {
          const tokenOwner = await contract.ownerOf(contractTokenID);
          if (tokenOwner) {
            setOwner(tokenOwner);
            setIsMine(
              tokenOwner.toLowerCase() === userWallet?.address?.toLowerCase()
            );
          } else {
            history.replace('/asset-processing');
            setOwner(null);
          }

          if (userWallet && userWallet.address) {
            setSalesContractApproved(
              await contract.isApprovedForAll(
                userWallet.address,
                Contracts.sales
              )
            );
            setAuctionContractApproved(
              await contract.isApprovedForAll(
                userWallet.address,
                Contracts.auction
              )
            );
          }
        }
      } catch (error) {
        console.log(error);
        history.replace('/asset-processing');
      }
    }
  };

  const fetchContractData = async (contractTokenID, createdNft) => {
    setContractFetching(true);

    if (contractTokenID) {
      const token = createdNft?.payToken;
      try {
        const saleContract = getReadContract(
          Contracts.sales,
          SALES_CONTRACT_ABI
        );
        if (
          saleContract &&
          createdNft &&
          createdNft.user &&
          createdNft.user.userWallets
        ) {
          const userAddress = createdNft.user?.userWallets[0]?.address;
          const listing = await saleContract.listings(
            Contracts.artion,
            contractTokenID,
            userAddress
          );

          if (listing.pricePerItem.toString() !== '0') {
            setListing({
              quantity: listing.quantity,
              owner: createdNft.user,
              token,
              price: parseFloat(
                ethers.utils.formatUnits(listing.pricePerItem, token.decimals)
              ),
              startTime: parseFloat(listing.startingTime.toString()),
            });
          } else {
            setListing(null);
          }

          if (userWallet) {
            const offer = await saleContract.offers(
              Contracts.artion,
              contractTokenID,
              userWallet?.address
            );

            const now = new Date(Date.now());
            const endDate = new Date(parseFloat(offer.deadline) * 1000);

            if (
              endDate.getTime() > now.getTime() &&
              offer.pricePerItem.toString() !== '0'
            ) {
              setHasMyOffer({
                token,
                quantity: offer.quantity,
                price: parseFloat(
                  ethers.utils.formatUnits(offer.pricePerItem, token.decimals)
                ),
                deadline: parseFloat(offer.deadline.toString()),
              });
            }
          } else {
            setHasMyOffer(null);
          }
        }

        const auctionContract = getReadContract(
          Contracts.auction,
          AUCTION_CONTRACT_ABI
        );

        if (
          auctionContract &&
          createdNft &&
          createdNft.user &&
          createdNft.user.userWallets
        ) {
          const userAddress = createdNft.user?.userWallets[0]?.address;
          const auction = await auctionContract.auctions(
            Contracts.artion,
            contractTokenID
          );
          if (userAddress.toLowerCase() === auction.owner.toLowerCase()) {
            const auctionTemp = {
              owner: createdNft.user,
              token,
              reservePrice: parseFloat(
                ethers.utils.formatUnits(auction.reservePrice, token.decimals)
              ),
              startTime: parseFloat(auction.startTime.toString()),
              endTime: parseFloat(auction.endTime.toString()),
              resulted: false,
            };
            setAuction(auctionTemp);
            getBid(auctionTemp);
          } else {
            setAuction(null);
            setBid(null);
          }
        }
        setContractFetching(false);
      } catch (error) {
        console.log(error);
        history.replace('/asset-processing');
      }
    }
  };

  const getTradeHistory = async tokenID => {
    if (tokenID) {
      const response = await getNftTradeHistories(tokenID);
      setTradeHistory(response.data);
    }
  };

  const getOffers = async tokenID => {
    if (tokenID) {
      const { data } = await getNftOffers(tokenID);
      const token = createdNft?.payToken;

      if (data.length > 0) {
        setOffers(
          data
            .filter(item => item.creatorId !== createdNft.userId)
            .map(item => {
              return {
                ...item,
                token,
              };
            })
        );
      }
    }
  };

  const handleTotalShares = async tokenID => {
    if (tokenID) {
      try {
        const { data } = await getTotalShare(tokenID);
        setTotalShare(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const eventMatches = (nft, id) => {
    return (
      Contracts.artion?.toLowerCase() === nft?.toLowerCase() &&
      parseFloat(contractTokenID) === parseFloat(id.toString())
    );
  };

  const itemListedHandler = async (
    owner,
    nft,
    id,
    quantity,
    paymentToken,
    pricePerItem,
    startingTime
  ) => {
    if (
      eventMatches(nft, id) &&
      owner.toLowerCase() === account?.toLowerCase()
    ) {
      const token = createdNft?.payToken;
      const newListing = {
        owner: user,
        quantity: parseFloat(quantity.toString()),
        token,
        price: parseFloat(
          ethers.utils.formatUnits(pricePerItem, token.decimals)
        ),
        startTime: parseFloat(startingTime.toString()),
      };

      setListing(newListing);
      showToast('success', 'Item listed successfully!');
    }
  };

  const itemUpdatedHandler = (owner, nft, id, paymentToken, newPrice) => {
    if (
      eventMatches(nft, id) &&
      owner.toLowerCase() === account?.toLowerCase()
    ) {
      const token = createdNft?.payToken;
      setListing(prev => ({
        ...prev,
        token: token,
        price: parseFloat(ethers.utils.formatUnits(newPrice, token.decimals)),
      }));

      showToast('success', 'Price updated successfully!');
    }
  };

  const itemCanceledHandler = (owner, nft, id) => {
    if (
      eventMatches(nft, id) &&
      owner.toLowerCase() === account?.toLowerCase()
    ) {
      setListing(null);
      showToast('success', 'Item unlisted successfully!');
    }
  };

  const itemSoldHandler = async (
    seller,
    buyer,
    nft,
    id,
    _quantity,
    paymentToken,
    unitPrice,
    price
  ) => {
    if (
      eventMatches(nft, id) &&
      buyer.toLowerCase() === account?.toLowerCase()
    ) {
      setListing(null);
      setOwner(buyer);
      setIsMine(true);
      setCreatedNft(item => ({
        ...item,
        user,
      }));
      setOffers(prev => prev.filter(item => item.creatorId !== user.userId));
      const token = createdNft?.payToken;

      const _price = parseFloat(
        ethers.utils.formatUnits(price, token.decimals)
      );
      const newTradeHistory = {
        tradeHistoryId: uuidv4(),
        price: _price,
        saleDate: Date.now(),
        paymentToken,
        token,
        priceUsd: _price,
      };
      try {
        const { data } = await getUserAccountDetails(seller, authToken);
        newTradeHistory.seller = data;
      } catch (e) {
        console.log(e);
      }
      try {
        const { data } = await getUserAccountDetails(buyer, authToken);
        newTradeHistory.buyer = data;
      } catch (e) {
        console.log(e);
      }
      setTradeHistory(prevItem => [newTradeHistory, ...prevItem]);
      showToast('success', 'You have bought the item!');
    }
  };

  const offerCreatedHandler = async (
    creator,
    nft,
    id,
    quantity,
    payToken,
    pricePerItem,
    deadline
  ) => {
    if (
      eventMatches(nft, id) &&
      creator.toLowerCase() === account?.toLowerCase()
    ) {
      const token = createdNft?.payToken;
      const newOffer = {
        offerId: uuidv4(),
        creatorId: user.userId,
        creator: user,
        deadline: new Date(parseFloat(deadline.toString()) * 1000),
        token,
        price: parseFloat(
          ethers.utils.formatUnits(pricePerItem, token.decimals)
        ),
        paymentToken: payToken,
      };

      setOffers(offers => [newOffer, ...offers]);
      setHasMyOffer(newOffer);
      showToast('success', 'Offer placed successfully!');
    }
  };

  const offerCanceledHandler = (creator, nft, id) => {
    if (
      eventMatches(nft, id) &&
      creator.toLowerCase() === account?.toLowerCase()
    ) {
      setOffers(item => item.filter(offer => offer.creatorId !== user.userId));
      setHasMyOffer();
      showToast('success', 'You have withdrawn your offer!');
    }
  };

  const auctionCreatedHandler = async (nft, id) => {
    if (eventMatches(nft, id)) {
      const auctionContract = getReadContract(
        Contracts.auction,
        AUCTION_CONTRACT_ABI
      );
      const auction = await auctionContract.auctions(
        Contracts.artion,
        contractTokenID
      );
      const token = createdNft?.payToken;

      const auctionTemp = {
        owner: createdNft.user,
        token,
        reservePrice: parseFloat(
          ethers.utils.formatUnits(auction.reservePrice, token.decimals)
        ),
        startTime: parseFloat(auction.startTime.toString()),
        endTime: parseFloat(auction.endTime.toString()),
        resulted: false,
      };
      setAuction(auctionTemp);
      showToast('success', 'Auction started!');
    }
  };

  const auctionEndTimeUpdatedHandler = (nft, id, _endTime) => {
    if (eventMatches(nft, id)) {
      const endTime = parseFloat(_endTime.toString());
      showToast('success', 'Auction end time updated successfully!');
      setAuction(item => ({ ...item, endTime }));
    }
  };

  const auctionStartTimeUpdatedHandler = (nft, id, _startTime) => {
    if (eventMatches(nft, id)) {
      const startTime = parseFloat(_startTime.toString());
      showToast('success', 'Auction start time updated successfully!');
      setAuction(item => ({ ...item, startTime }));
    }
  };

  const auctionReservePriceUpdatedHandler = (nft, id, _payToken, _price) => {
    if (eventMatches(nft, id)) {
      showToast('success', 'Auction reserve price updated successfully!');
      const price = ethers.utils.formatUnits(_price, auction.token.decimals);
      setAuction(item => ({ ...item, reservePrice: parseFloat(price) }));
    }
  };

  const bidPlacedHandler = (nft, id, bidder, _bid) => {
    if (eventMatches(nft, id)) {
      const bid = parseFloat(_bid.toString()) / 10 ** 6;
      setBid({
        bidder,
        bid,
        lastBidTime: Math.floor(new Date().getTime() / 1000),
      });
    }
  };

  const bidWithdrawnHandler = (nft, id) => {
    if (eventMatches(nft, id)) {
      setBid(null);
    }
  };

  const auctionCancelledHandler = (nft, id) => {
    if (eventMatches(nft, id)) {
      showToast('success', 'Auction canceled!');
      setAuction(null);
      setBid(null);
    }
  };

  const auctionResultedHandler = async (
    oldOwner,
    nft,
    id,
    winner,
    payToken,
    unitPrice,
    _winningBid
  ) => {
    if (
      eventMatches(nft, id) &&
      oldOwner.toLowerCase() === account?.toLowerCase()
    ) {
      setAuction();

      setOwner(winner);
      setIsMine(false);

      const token = createdNft?.payToken;
      const _price = parseFloat(
        ethers.utils.formatUnits(_winningBid, token.decimals)
      );
      const newTradeHistory = {
        tradeHistoryId: uuidv4(),
        price: _price,
        saleDate: Date.now(),
        paymentToken: payToken,
        token,
        priceUsd: _price,
      };

      try {
        const { data } = await getUserAccountDetails(account, authToken);
        newTradeHistory.seller = data;
      } catch (e) {
        console.log(e);
      }
      try {
        const { data } = await getUserAccountDetails(winner, authToken);
        newTradeHistory.buyer = data;
        setCreatedNft(item => ({
          ...item,
          user: data,
        }));
      } catch (e) {
        console.log(e);
      }

      setTradeHistory(prevItem => [newTradeHistory, ...prevItem]);
    }
  };

  const transferERC721Hander = async (from, to, tokenId) => {
    if (
      parseFloat(contractTokenID) === parseFloat(tokenId.toString()) &&
      from?.toLowerCase() ===
        process.env.REACT_APP_TRANSAK_CONTRACT?.toLowerCase() &&
      to?.toLowerCase() === userWallet?.address.toLowerCase()
    ) {
      const { data: userInfor } = await getUserByWalletAddress(to);
      setListing(null);
      setOwner(to);
      setIsMine(true);
      setCreatedNft(item => ({
        ...item,
        user: userInfor,
      }));
    }
  };

  const addEventListeners = async () => {
    const salesContract = await getSalesContract();
    const auctionContract = await getAuctionContract();
    const artionContract = await getERC721Contract();
    salesContract.on('ItemListed', itemListedHandler);
    salesContract.on('ItemUpdated', itemUpdatedHandler);
    salesContract.on('ItemCanceled', itemCanceledHandler);
    salesContract.on('ItemSold', itemSoldHandler);
    salesContract.on('OfferCreated', offerCreatedHandler);
    salesContract.on('OfferCanceled', offerCanceledHandler);

    prevSalesContract.current = salesContract;
    prevAuctionContract.current = auctionContract;

    auctionContract.on('AuctionCreated', auctionCreatedHandler);
    auctionContract.on(
      'UpdateAuctionStartTime',
      auctionStartTimeUpdatedHandler
    );
    auctionContract.on('UpdateAuctionEndTime', auctionEndTimeUpdatedHandler);
    auctionContract.on(
      'UpdateAuctionReservePrice',
      auctionReservePriceUpdatedHandler
    );
    auctionContract.on('BidPlaced', bidPlacedHandler);
    auctionContract.on('BidWithdrawn', bidWithdrawnHandler);
    auctionContract.on('AuctionCancelled', auctionCancelledHandler);
    auctionContract.on('AuctionResulted', auctionResultedHandler);

    artionContract.on('Transfer', transferERC721Hander);
  };

  const removeEventListeners = async () => {
    prevSalesContract.current?.removeAllListeners();
    prevAuctionContract.current?.removeAllListeners();
  };

  useEffect(() => {
    if (createdNft.nftId) {
      if (authToken) {
        increaseViewCount(authToken, createdNft.nftId, code);
      }
    }
  }, [authToken, createdNft.nftId]);

  useEffect(() => {
    if (!isMine && action === 'buy' && listing) {
      userNavigate(() => handleBuyItem(listing));
    }
  }, [action, listing, isMine]);

  useEffect(() => {
    if (createdNft?.payToken && account && contractTokenID) {
      addEventListeners();
    }
    return () => {
      removeEventListeners();
    };
  }, [contractTokenID, createdNft?.payToken, account]);

  useEffect(() => {
    const userWalletAddress = userWallet?.address;

    asyncNft();

    if (!userWalletAddress) {
      setIsMine(false);
      setHasMyOffer();
    }
  }, [authToken, userWallet?.address]);

  useEffect(() => {
    fetchNFTDetails(contractTokenID);
  }, [contractTokenID]);

  useEffect(() => {
    if (contractTokenID && createdNft && !createdNft?.processing) {
      fetchContractData(contractTokenID, createdNft);
    }
  }, [contractTokenID, createdNft]);

  useEffect(() => {
    getTradeHistory(createdNft.nftId);
    getOffers(createdNft.nftId);
    handleTotalShares(createdNft.nftId);
  }, [createdNft.nftId]);

  const userNavigate = action => {
    if (Object.keys(user).length === 0) {
      history.push('/auth/sign-in');
    } else if (!user.is2fa) {
      history.push('/profile/2fa');
    } else if (!user.address) {
      history.push('/profile/connect-wallet');
    } else if (user?.kycRequired) {
      history.push('/profile/kyc');
    } else if (user?.isAdmin) {
      showToast(
        'error',
        'This is an admin account. This action is not allowed.'
      );
    } else {
      action();
    }
  };

  const onShareClicked = () => {
    setShareModalVisible(true);
  };

  const onCommentsClicked = () => {
    setShowComments(prev => !prev);
  };

  const onLikesClicked = async () => {
    const { data } = await toggleLikeItem(createdNft.nftId, authToken);

    if (data?.message === 'OK') {
      setIsLiked(prev => {
        if (prev) {
          totalLikes.value = totalLikes.value - 1;
        } else {
          totalLikes.value = totalLikes.value + 1;
        }
        return !prev;
      });
    }
  };

  const handleRequestReprocessing = async () => {
    try {
      await requestReprocessing(authToken, createdNft.nftId);
      setCreatedNft(prev => ({ ...prev, processing: 'pending' }));
    } catch (error) {
      console.log(error);
    }
  };

  const renderAction = () => {
    return (
      <>
        <div className={styles.actionGroup}>
          {!contractFetching ? (
            <>
              {!isMine && listing && (
                <StyledButton
                  onClick={async () =>
                    userNavigate(() => handleBuyItem(listing))
                  }
                  width={isMobile ? '100%' : 'auto'}
                  disabled={buyingItem}
                  sx={{ minHeight: '40px' }}
                >
                  {buyingItem ? (
                    <ClipLoader color="#FFF" size={16} />
                  ) : (
                    'Buy Now'
                  )}
                </StyledButton>
              )}
              {isMine && !auction && (
                <StyledButton
                  onClick={async () => {
                    setSellModalVisible(true);
                  }}
                  width={isMobile ? '100%' : 'auto'}
                  disabled={
                    listingConfirming || cancelingListing || auctionStarting
                  }
                  sx={{ minHeight: '40px' }}
                >
                  {listingConfirming ? (
                    <ClipLoader color="#FFF" size={16} />
                  ) : listing ? (
                    'Update Listing'
                  ) : (
                    'List Your Asset'
                  )}
                </StyledButton>
              )}
              {isMine && listing && (
                <StyledButton
                  onClick={async () => handleCancelListing()}
                  width={isMobile ? '100%' : 'auto'}
                  disabled={cancelingListing || listingConfirming}
                  sx={{ minHeight: '40px' }}
                >
                  {cancelingListing ? (
                    <ClipLoader color="#FFF" size={16} />
                  ) : (
                    'Cancel Listing'
                  )}
                </StyledButton>
              )}
              {/* {isMine &&
                ((!listing && !auction) || (auction && !auctionEnded)) && (
                  <StyledButton
                    onClick={async () =>
                      !auctionEnded && setAuctionModalVisible(true)
                    }
                    width={isMobile ? '100%' : 'auto'}
                    disabled={
                      auctionStarting || auctionCanceling || listingConfirming
                    }
                    sx={{ minHeight: '40px' }}
                  >
                    {auctionStarting ? (
                      <ClipLoader color="#FFF" size={16} />
                    ) : auction ? (
                      'Update Auction'
                    ) : (
                      'Start Auction'
                    )}
                  </StyledButton>
                )} */}
              {/* {isMine && auction && !auction.isResulted && (
                <StyledButton
                  onClick={
                    (bid && bid.bid < auction.reservePrice) ||
                    !auctionEnded ||
                    !bid
                      ? handleCancelAuction
                      : handleResultAuction
                  }
                  width={isMobile ? '100%' : 'auto'}
                  disabled={auctionCanceling}
                  sx={{ minHeight: '40px' }}
                >
                  {auctionCanceling ? (
                    <ClipLoader color="#FFF" size={16} />
                  ) : (bid && bid.bid < auction.reservePrice) ||
                    !auctionEnded ||
                    !bid ? (
                    'Cancel Auction'
                  ) : (
                    'Accept the Highest Bid'
                  )}
                </StyledButton>
              )} */}
              {/* {!isMine && auction && !auctionEnded && (
                <StyledButton
                  onClick={() => userNavigate(() => setBidModalVisible(true))}
                  width={isMobile ? '100%' : 'auto'}
                  disabled={bidPlacing}
                  sx={{ minHeight: '40px' }}
                >
                  {bidPlacing ? (
                    <ClipLoader color="#FFF" size={16} />
                  ) : (
                    'Place Bid'
                  )}
                </StyledButton>
              )}
              {!isMine && user && bid && account === bid.bidder && auction && (
                <StyledButton
                  onClick={() => handleWithdrawBid()}
                  width={isMobile ? '100%' : 'auto'}
                  disabled={bidWithdrawing}
                  sx={{ minHeight: '40px' }}
                >
                  {bidWithdrawing ? (
                    <ClipLoader color="#FFF" size={16} />
                  ) : (
                    'Withdraw Bid'
                  )}
                </StyledButton>
              )} */}
              {!isMine && !auction && (
                <StyledButton
                  onClick={
                    hasMyOffer
                      ? handleCancelOffer
                      : () => userNavigate(() => setOfferModalVisible(true))
                  }
                  width={isMobile ? '100%' : 'auto'}
                  disabled={offerConfirming}
                  sx={{ minHeight: '40px' }}
                >
                  {offerConfirming ? (
                    <ClipLoader color="#FFF" size={16} />
                  ) : hasMyOffer ? (
                    'Withdraw Offer'
                  ) : (
                    'Make Offer'
                  )}
                </StyledButton>
              )}
            </>
          ) : (
            <div className={styles.actionLoading}>
              <ClipLoader color="var(--primary-color)" size={20} />
            </div>
          )}
          {isMine && (
            <StyledButton
              onClick={async () => handleDownloadFile()}
              width={isMobile ? '100%' : 'auto'}
              sx={{ minHeight: '40px' }}
            >
              Download
            </StyledButton>
          )}
        </div>

        <NftDetailsButtons
          showComments={showComments}
          onShareClicked={onShareClicked}
          onCommentsClicked={onCommentsClicked}
          onLikesClicked={onLikesClicked}
          totalShare={totalShare}
          totalLike={createdNft.likes}
          isLiked={isLiked}
        />
      </>
    );
  };

  return (
    <div className={cx(styles.container)}>
      {Object.keys(createdNft).length > 0 && (
        <MetaTags
          title={createdNft.name}
          description={createdNft.description}
          image={thumbnailUrl ? thumbnailUrl : ''}
          video={thumbnailVideoUrl ? thumbnailVideoUrl : ''}
          name={createdNft.user?.name}
        />
      )}
      <Header isSticky="sticky" />
      <StyledBackdrop open={loading} sx={{ minHeight: '40px' }}>
        <Loader
          type="Oval"
          color="var(--primary-color)"
          height={50}
          width={50}
        />
      </StyledBackdrop>
      {Object.keys(createdNft).length > 0 && (
        <Grid container className={styles.body}>
          <Grid item xs={12} md={8} className={styles.thumbnailContainer}>
            <div className={cx(styles.ad, styles.hidden)}>
              {/* remove 'hidden' to show ad */}
              Advertisement
            </div>
            {assets.length > 0 && (
              <Carousel
                items={assets}
                thumbnailUrl={thumbnailUrl}
                posterUrl={posterUrl}
                isMobile={isMobile}
                nftName={createdNft.name}
                ownerName={createdNft.user.name}
                setCarouselIndex={setCarouselIndex}
              />
            )}
            {!isMobile && showComments && (
              <Comments
                user={user}
                nft={createdNft}
                setFirstCommentsLoad={setFirstCommentsLoad}
                firstCommentsLoad={firstCommentsLoad}
              />
            )}

            {!isMobile && (
              <MasonaryNFTs
                sortBy="createdAt"
                userId={createdNft?.user.userId}
                userName={createdNft?.user.name}
                itemsPerPage={10}
                onlyMinted
                showLoadMoreButton
                exceptNftId={createdNft.nftId} // Avoid showing this NFT
                openInNewTab
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            ref={ref}
            className={cx(styles.details, styles.fullSize)}
          >
            <div className={styles.detailsContent}>
              <div className={styles.title}>
                <span>{createdNft?.name}</span>
                {!isMine && authToken && (
                  <span className={styles.actions}>
                    <ActionMenu
                      isMobile={isMobile}
                      onNftFlagged={handleNftFlagged}
                    />
                  </span>
                )}
              </div>
              <div className={styles.itemStats}>
                {loading ? (
                  <Skeleton width={150} height={20} />
                ) : (
                  <>
                    {createdNft?.user?.avatar ? (
                      <img
                        src={`${
                          createdNft?.user?.avatar.includes('mypinata')
                            ? `${createdNft?.user?.avatar}/?pinataGatewayToken=${process.env.REACT_APP_IPFS_ACCESS_KEY}`
                            : createdNft?.user?.avatar
                        }`}
                        className={styles.ownerAvatar}
                      />
                    ) : (
                      <div className={styles.ownerAvatar}>
                        <img
                          src={defaultAvatar}
                          alt={'avatar'}
                          className={styles.avatar}
                        />
                      </div>
                    )}
                    <>
                      Owned by&nbsp;
                      <Link
                        to={
                          isMine
                            ? `/profile`
                            : `/account/${createdNft.user?.name.replaceAll(
                                ' ',
                                '+'
                              )}`
                        }
                        className={styles.ownerName}
                      >
                        {isMine ? 'Me' : createdNft?.user?.name}
                      </Link>
                    </>
                  </>
                )}
              </div>
              {isMine && createdNft.processing === 'pending' && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box fontSize="13px" color="var(--primary-color)">
                    Your Asset is processing
                  </Box>
                  <IconLoading />
                </Box>
              )}
              {isMine && createdNft.processing === 'failed' && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box fontSize="13px" color="var(--primary-color)">
                    Your Asset failed to process
                  </Box>
                  <StyledButton
                    className={styles.textButton}
                    secondary="true"
                    onClick={handleRequestReprocessing}
                    sx={{
                      p: 0,
                      ml: 1,
                    }}
                  >
                    Retry
                  </StyledButton>
                </Box>
              )}
              {
                <>
                  {listing && (
                    <div className={styles.currentPriceContainer}>
                      <div className={styles.currentPriceTitle}>
                        Current price
                      </div>
                      <div className={styles.currentPriceValue}>
                        {/* <img src={listing.token?.icon} alt="token icon" /> */}
                        <div className={styles.currentPrice}>
                          {`$${formatNumber(listing.price)} USD`}
                        </div>
                      </div>
                    </div>
                  )}
                  {auction && !auction?.resulted && (
                    <div className={styles.currentPriceContainer}>
                      <div className={styles.currentPriceTitle}>
                        {auctionStarted
                          ? auctionEnded
                            ? 'Sale ended'
                            : `Sale ends in ${formatDuration(
                                auction.endTime
                              )} (${new Date(
                                auction.endTime * 1000
                              ).toLocaleString()})`
                          : `Sale starts in ${formatDuration(
                              auction.startTime
                            )}`}
                      </div>
                      <div className={styles.currentPriceValue}>
                        Reserve Price :&nbsp;
                        <img src={auction.token?.icon} alt="token icon" />
                        <div className={styles.currentPrice}>
                          {`${formatNumber(auction.reservePrice)} ${
                            auction.token?.symbol
                          }`}
                        </div>
                      </div>
                      {bid && (
                        <div className={styles.currentPriceValue}>
                          Highest Bid :&nbsp;
                          <img src={auction.token?.icon} alt="token icon" />
                          <div className={styles.currentPrice}>
                            {`${formatNumber(bid.bid)} ${
                              auction.token?.symbol
                            }`}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {!createdNft.processing && renderAction()}
                </>
              }
            </div>
            <div className={styles.description}>
              <Description description={createdNft?.description} />
            </div>
            <div className={styles.scrollContainer}>
              <div
                className={cx(
                  styles.scrollContent,
                  showComments && styles.fullSize
                )}
              >
                <PropertyItem
                  title={
                    <>
                      <AppRegistrationOutlined />
                      Registry
                    </>
                  }
                >
                  <Grid container className={styles.itemsList}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={styles.documentContainer}
                    >
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>Category</div>
                        <div className={styles.registryValue}>
                          {createdNft?.category.type}
                        </div>
                      </div>
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>Subcategory</div>
                        <div
                          className={cx(styles.registryValue, styles.tagValue)}
                        >
                          {createdNft?.subcategories?.map((item, index) => (
                            <div className={styles.tagItem} key={index}>
                              {item.type}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>Tags</div>
                        <div
                          className={cx(styles.registryValue, styles.tagValue)}
                        >
                          {createdNft?.tags &&
                            createdNft?.tags.map(item => (
                              <Link
                                to={`/search/${item.name}`}
                                className={styles.tagItem}
                                key={item.tagId}
                              >
                                {item.name}
                              </Link>
                            ))}
                        </div>
                      </div>
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>
                          Asset Owner is Licensed Under
                        </div>
                        <div className={styles.registryValue}>
                          {createdNft?.contractType
                            ? createdNft.contractType.name
                            : ''}
                        </div>
                      </div>
                      {
                        <div className={styles.documentPaddings}>
                          <div className={styles.registryLabel}>
                            Artist Name
                          </div>
                          <div className={styles.registryValue}>
                            {createdNft?.artist
                              ? createdNft?.artist?.name
                              : '---'}
                          </div>
                        </div>
                      }
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>Royalty</div>
                        <div className={styles.registryValue}>
                          {createdNft?.royalty}%
                        </div>
                      </div>
                      {createdNft?.artist?.website &&
                        createdNft?.artist?.website !== 'undefined' && (
                          <div className={styles.documentPaddings}>
                            <div className={styles.registryLabel}>
                              Artist Website
                            </div>
                            <div className={styles.registryValue}>
                              {createdNft?.artist?.website ? (
                                <a
                                  href={`https://${createdNft?.artist_website}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={styles.link}
                                >
                                  {createdNft?.artist?.website}
                                </a>
                              ) : (
                                '---'
                              )}
                            </div>
                          </div>
                        )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={styles.documentContainer}
                    >
                      {contractType && (
                        <div className={styles.documentPaddings}>
                          <div className={styles.registryLabel}>
                            {contractType.label}
                          </div>
                          <div className={styles.registryValue}>
                            {renderFiles([
                              {
                                url: contractType.url,
                                asset_id: contractType.id,
                              },
                            ])}
                          </div>
                        </div>
                      )}
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>
                          Model/Actor Releases
                        </div>
                        <div>
                          {createdNft?.actorModelReleases &&
                          createdNft?.actorModelReleases.length
                            ? renderFiles(createdNft?.actorModelReleases)
                            : 'No files attached.'}
                        </div>
                      </div>
                      <div className={styles.documentPaddings}>
                        <div className={styles.registryLabel}>
                          Additional Documents
                        </div>
                        <div>
                          {createdNft?.additionalDocuments &&
                          createdNft?.additionalDocuments.length
                            ? renderFiles(createdNft?.additionalDocuments)
                            : 'No files attached.'}
                        </div>
                      </div>
                      {promo.length > 0 && sourceFileURL && (
                        <StyledButton onClick={() => toggleShowSourceFile()}>
                          {!showSourceFile
                            ? `See Asset${sourceFileURL.length > 0 ? 's' : ''}`
                            : 'See Promo Content'}
                        </StyledButton>
                      )}
                    </Grid>
                  </Grid>
                </PropertyItem>
                <PropertyItem
                  title={
                    <>
                      <InfoOutlined />
                      Properties
                    </>
                  }
                >
                  <div className={styles.panelBody}>
                    <div className={styles.panelLine}>
                      <div className={styles.panelLabel}>Contract Address</div>
                      <a
                        href={`${explorerUrl}/token/${Contracts.artion}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        {shortenAddress(Contracts.artion)}
                      </a>
                    </div>
                    <div className={styles.panelLine}>
                      <div className={styles.panelLabel}>Token ID</div>
                      <div className={styles.panelValue}>
                        <a
                          href={`${explorerUrl}/token/${Contracts.artion}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.link}
                        >
                          {contractTokenID}
                        </a>
                      </div>
                    </div>
                    <div className={styles.panelLine}>
                      <div className={styles.panelLabel}>Network</div>
                      <div className={styles.panelValue}>Sepolia</div>
                    </div>
                  </div>
                </PropertyItem>
                {createdNft?.attributes && (
                  <PropertyItem
                    title={
                      <>
                        <AttachmentOutlined />
                        Attributes
                      </>
                    }
                  >
                    <div className={styles.panelBody}>
                      {createdNft.attributes.map(item => (
                        <div className={styles.panelLine} key={item.attribute}>
                          <div className={styles.panelLabel}>
                            {item.attribute}
                          </div>
                          <div className={styles.panelValue}>{item.value}</div>
                        </div>
                      ))}
                    </div>
                  </PropertyItem>
                )}
                <PropertyItem
                  title={
                    <>
                      <SellOutlined />
                      Direct Offers
                    </>
                  }
                  expanded={offers.length > 0}
                >
                  {offers.map(item => (
                    <Offer
                      key={item.offerId}
                      authToken={authToken}
                      user={item.creator}
                      price={item.price}
                      deadline={item.deadline}
                      token={createdNft?.payToken}
                      isMine={isMine}
                      salesContractApproved={salesContractApproved}
                      offerAccepting={offerAccepting}
                      salesContractApproving={salesContractApproving}
                      handleAcceptOffer={handleAcceptOffer}
                      handleApproveSalesContract={handleApproveSalesContract}
                    />
                  ))}
                </PropertyItem>
                <PropertyItem
                  title={
                    <>
                      <AttachMoneyOutlined />
                      Price History
                    </>
                  }
                >
                  {width > 0 ? (
                    <div className={styles.chartWrapper}>
                      <div className={styles.chart}>
                        <LineChart
                          width={width - 100}
                          height={250}
                          data={priceHistory}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <XAxis dataKey="date" />
                          <YAxis />
                          <ChartTooltip />
                          <CartesianGrid stroke="#eee" />
                          <Line
                            type="monotone"
                            dataKey="price"
                            stroke="#2479FA"
                          />
                        </LineChart>
                      </div>
                    </div>
                  ) : (
                    <div>{width}</div>
                  )}
                </PropertyItem>
                <PropertyItem
                  title={
                    <>
                      <MovingOutlined />
                      Trade History
                    </>
                  }
                >
                  {tradeHistory.map(item => (
                    <TradeHistory
                      buyer={item.buyer}
                      seller={item.seller}
                      price={item.price}
                      date={item.saleDate}
                      token={createdNft.payToken}
                      key={item.tradeHistoryId}
                    />
                  ))}
                </PropertyItem>
              </div>
            </div>

            {isMobile && showComments && (
              <div className={styles.commentsMobile}>
                <Comments
                  user={user}
                  nft={createdNft}
                  isMobile
                  setFirstCommentsLoad={setFirstCommentsLoad}
                  firstCommentsLoad={firstCommentsLoad}
                />
              </div>
            )}
            {isMobile && (
              <MasonaryNFTs
                sortBy="createdAt"
                userId={createdNft?.user.userId}
                userName={createdNft?.user.name}
                itemsPerPage={10}
                onlyMinted
                showLoadMoreButton
                exceptNftId={createdNft.nftId} // Avoid showing this NFT
                openInNewTab
              />
            )}
            <div className={styles.ad}>Advertisement</div>
          </Grid>
        </Grid>
      )}

      <SellModal
        visible={sellModalVisible}
        onClose={() => setSellModalVisible(false)}
        onSell={listing ? handleUpdateListing : handleListItem}
        startPrice={listing?.price || 0}
        payToken={createdNft && createdNft.payToken}
        confirming={listingConfirming}
        approveContract={handleApproveSalesContract}
        contractApproving={salesContractApproving}
        contractApproved={salesContractApproved}
      />
      <DownloadModal
        visible={downloadModalVisible}
        onClose={() => {
          setDownloadModalVisible(false);
          setDownloadFiles([]);
        }}
        downloadFiles={downloadFiles}
        nftName={createdNft?.name}
      />
      <OfferModal
        visible={offerModalVisible}
        onClose={() => setOfferModalVisible(false)}
        onMakeOffer={handleMakeOffer}
        confirming={offerConfirming}
        approveContract={handleApproveERC20Contract}
        contractApproving={erc20ContractApproving}
        contractApproved={erc20ContractApproved}
        token={createdNft?.payToken}
      />
      <AuctionModal
        visible={auctionModalVisible}
        onClose={() => setAuctionModalVisible(false)}
        onStartAuction={auction ? handleUpdateAuction : handleStartAuction}
        auction={auction}
        auctionStarted={auctionStarted}
        confirming={auctionStarting}
        approveContract={handleApproveAuctionContract}
        contractApproving={auctionContractApproving}
        contractApproved={auctionContractApproved}
        token={createdNft?.payToken}
      />
      {Object.keys(createdNft).length > 0 && (
        <ShareModal
          visible={shareModalVisible}
          onClose={() => setShareModalVisible(false)}
          nft={createdNft}
        />
      )}
      <CarouselModal items={assets} startIndex={carouselIndex} />

      <BidModal
        visible={bidModalVisible}
        onClose={() => setBidModalVisible(false)}
        onPlaceBid={handlePlaceBid}
        minBidAmount={
          bid?.bid ? String(bid?.bid) : String(auction?.reservePrice)
        }
        confirming={bidPlacing}
        token={auction?.token}
        firstBid={bid?.bid ? false : auction?.reservePrice > 0 ? true : false}
      />
      <KYCModal
        visible={kycModalVisible}
        onClose={() => setKycModalVisible(false)}
      />
      <FooterAction hideFooter={authToken} />
    </div>
  );
};

export default index;
