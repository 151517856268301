import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import styles from './styles.module.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckIcon from '@mui/icons-material/Check';
import IconCopy from '@mui/icons-material/ContentCopy';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  RedditIcon,
  RedditShareButton,
} from 'react-share';
import DiscordIcon from 'assets/icons/iconDiscord.png';
import YoutubeIcon from 'assets/icons/iconYoutube.webp';
import InstagramIcon from 'assets/icons/iconInstagram.webp';
import TiktokIcon from 'assets/icons/iconTiktok.webp';
import BlueSkyIcon from 'assets/icons/iconBlueSky.png';
import { useApi } from 'api';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { StyledButton } from 'components/StyledComponents';
const ShareModal = ({ visible, onClose, nft }) => {
  const { createShare } = useApi();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const url =
    nft && nft.name
      ? `${window.location.origin}/explore/${nft.name
          .toLowerCase()
          .replaceAll(' ', '+')}${code ? `?code=${code}` : ''}`
      : '';
  const { authToken } = useSelector(state => state.Auth);
  const [copied, setCopied] = useState(false);

  const handleCopyAddress = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const getLinkToNFT = async () => {
    if (nft) {
      setLoading(true);
      if (authToken) {
        const { data } = await createShare(nft.nftId, authToken);
        if (data) {
          setCode(data);
        }
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      getLinkToNFT();
    }
  }, [visible]);
  return (
    <Modal
      visible={visible}
      title={`Share ${nft.name}`}
      onClose={onClose}
      showCloseIcon={false}
      defaultPadding={false}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.socialList}>
          <EmailShareButton url={url} disabled={loading}>
            <EmailIcon size={32} round />
          </EmailShareButton>
          <WhatsappShareButton url={url} disabled={loading}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <TelegramShareButton url={url} disabled={loading}>
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          <FacebookShareButton url={url} disabled={loading}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton url={url} disabled={loading}>
            <XIcon size={32} round />
          </TwitterShareButton>
          <LinkedinShareButton url={url} disabled={loading}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <RedditShareButton url={url} disabled={loading}>
            <RedditIcon size={32} round />
          </RedditShareButton>
          <div className={styles.disableIcon}>
            <img src={DiscordIcon} alt="youtube" />
          </div>
          <div className={styles.disableIcon}>
            <img src={YoutubeIcon} alt="youtube" />
          </div>
          <div className={styles.disableIcon}>
            <img src={InstagramIcon} alt="youtube" />
          </div>
          <div className={styles.disableIcon}>
            <img src={TiktokIcon} alt="youtube" />
          </div>
          <div className={styles.disableIcon}>
            <img src={BlueSkyIcon} alt="youtube" />
          </div>
        </div>
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
          }}
        >
          <CopyToClipboard text={url} onCopy={handleCopyAddress}>
            <StyledButton
              endIcon={
                copied ? (
                  <CheckIcon sx={{ color: 'darkblue', mr: '5px' }} />
                ) : (
                  <IconCopy />
                )
              }
              className={styles.link}
            >
              <div className={styles.text}>{url}</div>
            </StyledButton>
          </CopyToClipboard>
        </Box>
      </div>
    </Modal>
  );
};

export default ShareModal;
