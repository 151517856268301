import React from 'react';
import styles from './../styles.module.scss';
import LicenseGrid from '../ExternalDocumentsGrid';

import Header from 'components/header';
import FooterAction from '../../../components/FooterAction';

const ExclusiveContract = () => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.authContainer}>
        <Header border />
        <LicenseGrid
          lastUpdated="1 Jan 2024"
          title="Exclusive Contract"
          description=""
          url="/files/exclusive contract.pdf"
        >
          <div className={styles.authForm}>
            <h2>
              EACH PERSON WHO SELLS, PURCHASES OR OTHERWISE POSSESSES
              PROPRIETARY MEDIA (AS DEFINED HEREIN) UNDER CIRCUMSTANCES
              INDICATING THAT SUCH PROPRIETARY MEDIA IS SUBJECT TO THIS FORM OF
              LICENSE, THEREBY, WITHOUT FURTHER ACTION, AGREES TO BE BOUND BY
              THE TERMS OF THIS MINTI PROPRIETARY MEDIA SALE AND LICENSE
              AGREEMENT.
            </h2>
            <p>
              This MINTI Proprietary Media (“PM”) Sale and License Agreement
              (this “License”) sets forth the terms and conditions (“Terms”)
              governing each Asset lawfully offered, sold or transferred under
              circumstances indicating to a reasonable person that Creator
              (defined below) intends that the Asset and related PM or the
              offer, sale, use, or transfer of the Asset and related PM shall be
              governed hereby. Without limiting the generality of the foregoing,
              any PM shall be governed by these Terms if the Asset Metadata
              includes a statement that the Asset and related PM shall be
              governed by these Terms. The Company, Creator and Collector (each
              as defined below) shall be parties to this License.
            </p>
          </div>
        </LicenseGrid>
      </div>
      <FooterAction />
    </div>
  );
};
export default ExclusiveContract;
